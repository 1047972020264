import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { FaChevronCircleDown } from "react-icons/fa";
import 'react-calendar/dist/Calendar.css';

const AppointmentsData = ({ onSelect }) => {  // Ensure onSelect is destructured correctly
    const [appointmentsData, setAppointmentsData] = useState('Weekly');
    const [show, setShow] = useState(false);
    const [calendarShow, setCalendarShow] = useState(false);
    const [value, onChange] = useState(new Date());

    const handleDateChange = (newValue) => {
        onChange(newValue);
        setCalendarShow(false);
        if (typeof onSelect === 'function') {
            onSelect('calendar', newValue); // Call onSelect only if it's a function
        }
    };

    const handleSelectionChange = (selection) => {
        setAppointmentsData(selection);
        setShow(false);
        setCalendarShow(selection === 'calendar');
        if (typeof onSelect === 'function') {
            onSelect(selection, value); // Call onSelect only if it's a function
        }
    };

    return (
        <>
            <div className='relative'>
                <div className="flex gap-3 items-center">
                    <span className='text-[20px] text-primary capitalize'>{appointmentsData}</span>
                    <FaChevronCircleDown className='text-base text-primary cursor-pointer' onClick={() => setShow(!show)} />
                </div>
                <div className={`flex-col md:translate-x-1/2 absolute left-0 top-full bg-white ${show ? "flex" : "hidden"}`}>
                    <label htmlFor="weekly" className='block border py-2 px-4 text-base cursor-pointer'>Weekly
                        <input type="radio" className='hidden' name="appointmentsData" id="weekly" value='weekly' checked={appointmentsData === 'weekly'} onChange={() => handleSelectionChange('weekly')} />
                    </label>
                    <label htmlFor="today" className='block border py-2 px-4 text-base cursor-pointer'>Today
                        <input type="radio" className='hidden' name="appointmentsData" id="today" value='today' checked={appointmentsData === 'today'} onChange={() => handleSelectionChange('today')} />
                    </label>
                    <label htmlFor="calendar" className='block border py-2 px-4 text-base cursor-pointer'>Calendar
                        <input type="radio" className='hidden' name="appointmentsData" id="calendar" value='calendar' checked={appointmentsData === 'calendar'} onChange={() => handleSelectionChange('calendar')} />
                    </label>
                </div>
                <div className={`flex-col absolute lg:left-0 right-0 lg:right-auto w-[300px] top-full pt-4 bg-white ${calendarShow ? "flex" : "hidden"}`}>
                    <Calendar onChange={handleDateChange} value={value} />
                </div>
            </div>
        </>
    );
}

export default AppointmentsData;
