import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CgProfile } from "react-icons/cg";
import { SlCalender } from "react-icons/sl";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { RiCalendarScheduleLine } from "react-icons/ri";
import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
const baseUrl = "/purple-cloud";

function CustomTabPanel(props) {
    const { children, value, index, className, ...other } = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={className}
            {...other}
        >
            {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function ViewClientDetail() {
    const [value, setValue] = React.useState(0);
    const { clientId } = useParams();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <div className='w-full min-h-screen grid grid-cols-12 gap-4 flex-col-reverse'>
                <div className='lg:col-span-8 col-span-12 rounded  lg:px-0'>
                    <div className='flex items-start justify-between md:flex-row flex-col gap-5 md:gap-0'>
                        <div className='flex flex-col'>
                            <div className='flex items-center gap-5'>
                                <div>
                                    <p className='text-[16px] text-primary font-bold mb-0'>24463524</p>
                                    <h4 className='text-[28px] text-primary font-bold'>Client, First</h4>
                                </div>
                                <div className='text-[#c7c7c7] text-[14px]'>
                                    DOB: 4/10/24 | SS# SSN
                                </div>
                            </div>
                            <div className='flex sm:gap-3 gap-2 mt-3 sm:mt-0 text-[14px] text-primary font-bold sm:flex-row flex-col'>
                                <span>(123) 456-7890</span>
                                <span>test_a@gmail.com</span>
                                <span>Address, state, city 123456</span>
                            </div>
                        </div>
                        <div className='flex flex-col' key={clientId}>
                            <Link to={`${baseUrl}/clients/update/${clientId}`} className="text-[14px] font-bold text-primary flex gap-2 items-center">
                                <CgProfile /> Client Diagnostics
                            </Link>
                            <Link to={`${baseUrl}/diagnostic/add/${clientId}`} className="text-[14px] font-bold text-primary flex gap-2 items-center">
                                <SlCalender /> Diagnostic Evaluation
                            </Link>
                        </div>
                    </div>
                    <div className='w-full'>
                        <span className='flex border-2 border-dashed rounded-lg border-[#efeaf8 ] w-max px-2 text-[#efeaf8] cursor-pointer my-2'>+ Diagnosis</span>
                    </div>
                    <div className=''>
                        <h5 className='text-[14px] text-primary font-bold'>Upcoming Appointment</h5>
                        <hr className='my-2' />
                        <h5 className='text-[14px] text-primary font-bold'>No Appointments.</h5>
                        <hr className='my-2' />
                        <h5 className='text-[14px] text-primary font-bold'>Open Notes</h5>
                        <hr className='my-2' />
                        <div className="flex flex-col gap-5">
                            <div className="flex justify-between">
                                <div className="flex items-center gap-5">
                                    <span className="bg-[#049AE4] w-[30px] h-[30px] rounded-full flex-shrink-0"></span>
                                    <span className="text-primary text-[16px] font-bold">4/30/24 10:00am</span>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <span>
                                        <Link to="#" className="text-[20px] font-bold text-primary">
                                            <MdOutlineEdit />
                                        </Link>
                                    </span>
                                    <span>
                                        <Link to="#" className="text-[20px] font-bold text-primary">
                                            <RiDeleteBin6Fill />
                                        </Link>
                                    </span>
                                    <Link
                                        to={`${baseUrl}/welcome/dashboard`}
                                        className="text-[14px] font-bold text-white bg-primary flex gap-2 items-center px-10 rounded-xl py-1"
                                    >
                                        Add Note
                                    </Link>
                                    <span className="flex bg-primary text-white rounded-full p-2 max-w-fit text-[10px] font-bold">TD</span>
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <div className="flex items-center gap-5">
                                    <span className="bg-[#FFEBCD] w-[30px] h-[30px] rounded-full flex-shrink-0"></span>
                                    <span className="text-primary text-[16px] font-bold">4/30/24 10:00am</span>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <span>
                                        <Link to="#" className="text-[20px] font-bold text-primary">
                                            <MdOutlineEdit />
                                        </Link>
                                    </span>
                                    <span>
                                        <Link to="#" className="text-[20px] font-bold text-primary">
                                            <RiDeleteBin6Fill />
                                        </Link>
                                    </span>
                                    <Link
                                        to={`${baseUrl}/welcome/dashboard`}
                                        className="text-[14px] font-bold text-white bg-primary flex gap-2 items-center px-10 rounded-xl py-1"
                                    >
                                        Add Note
                                    </Link>
                                    <span className="flex bg-primary text-white rounded-full p-2 max-w-fit text-[10px] font-bold">TD</span>
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <div className="flex items-center gap-5">
                                    <span className="bg-[#049AE4] w-[30px] h-[30px] rounded-full flex-shrink-0"></span>
                                    <span className="text-primary text-[16px] font-bold">4/30/24 10:00am</span>
                                </div>
                                <div className="flex gap-2 items-center">
                                    <span>
                                        <Link to="#" className="text-[20px] font-bold text-primary">
                                            <MdOutlineEdit />
                                        </Link>
                                    </span>
                                    <span>
                                        <Link to="#" className="text-[20px] font-bold text-primary">
                                            <RiDeleteBin6Fill />
                                        </Link>
                                    </span>
                                    <Link
                                        to={`${baseUrl}/welcome/dashboard`}
                                        className="text-[14px] font-bold text-white bg-primary flex gap-2 items-center px-10 rounded-xl py-1"
                                    >
                                        Add Note
                                    </Link>
                                    <span className="flex bg-primary text-white rounded-full p-2 max-w-fit text-[10px] font-bold">TD</span>
                                </div>
                            </div>
                            <hr className="my-2" />
                        </div>

                    </div>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Activity" {...a11yProps(0)} sx={{ fontSize: '12px', color: '#7A65AC !important', fontWeight: 'bold' }} />
                                <Tab label="Therapy Notes" {...a11yProps(1)} sx={{ fontSize: '12px', color: '#7A65AC !important', fontWeight: 'bold' }} />
                                <Tab label="Documents" {...a11yProps(2)} sx={{ fontSize: '12px', color: '#7A65AC !important', fontWeight: 'bold' }} />
                                <Tab label="Billing" {...a11yProps(3)} sx={{ fontSize: '12px', color: '#7A65AC !important', fontWeight: 'bold' }} />
                                <Tab label="Notifications" {...a11yProps(4)} sx={{ fontSize: '12px', color: '#7A65AC !important', fontWeight: 'bold' }} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0} className="your-tab-panel-class">
                            <div className="overflow-x-auto">
                                {/* Content for Activity Tab */}
                                <form action="#" method="post">
                                    <div className='my-5 flex gap-3'>
                                        <input type="text" name="status" id="status" placeholder='Status Update' className='w-full border border-[#c7c7c7] px-3 py-2 rounded-xl focus:outline-none text-primary' />
                                        <Link to="#" className="text-[12px] font-bold text-white bg-primary h-100 py-2 px-4 rounded-xl flex items-center">
                                            Add
                                        </Link>
                                    </div>
                                    <div className='flex flex-col gap-3'>
                                        <div className='text-primary flex gap-3 items-center'>
                                            <Link to="#" className="text-[15px] text-primary">
                                                <RiDeleteBin6Fill />
                                            </Link>
                                            <span className='text-primary'>7/24/24</span>
                                            <p className='ml-2'>New Appointment: <Link to="#" className="text-[15px] text-primary">Jul 24, 2024 at 12:00AM</Link></p>
                                        </div>
                                        <div className='text-primary flex gap-3 items-center'>
                                            <Link to="#" className="text-[15px] text-primary">
                                                <RiDeleteBin6Fill />
                                            </Link>
                                            <span className='text-primary'>7/24/24</span>
                                            <p className='ml-2'>New Appointment: <Link to="#" className="text-[15px] text-primary">Jul 24, 2024 at 12:00AM</Link></p>
                                        </div>
                                        <div className='text-primary flex gap-3 items-center'>
                                            <Link to="#" className="text-[15px] text-primary">
                                                <RiDeleteBin6Fill />
                                            </Link>
                                            <span className='text-primary'>7/24/24</span>
                                            <p className='ml-2'>New Appointment: <Link to="#" className="text-[15px] text-primary">Jul 24, 2024 at 12:00AM</Link></p>
                                        </div>
                                        <div className='text-primary flex gap-3 items-center'>
                                            <Link to="#" className="text-[15px] text-primary">
                                                <RiDeleteBin6Fill />
                                            </Link>
                                            <span className='text-primary'>7/24/24</span>
                                            <p className='ml-2'>New Appointment: <Link to="#" className="text-[15px] text-primary">Jul 24, 2024 at 12:00AM</Link></p>
                                        </div>
                                    </div>
                                </form>
                                {/* Content for Activity Tab */}
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1} className="your-tab-panel-class">
                            <div className="overflow-x-auto">
                                {/* Content for Therapy Notes Tab start*/}
                                <div className="overflow-x-auto">
                                    <table class="table-auto w-full my-5">
                                        <thead className='border-t border-b border-gray-200'>
                                            <tr className='text-[14px] uppercase'>
                                                <th className='text-left text-tablehead font-normal'>Date</th>
                                                <th className='text-left text-tablehead font-normal'>THERAPIST</th>
                                                <th className='text-left text-tablehead font-normal'>DIAGNOSIS</th>
                                                <th className='text-left text-tablehead font-normal'>BILLING CODE</th>
                                                <th className='text-left text-tablehead font-normal'>CO-PAY</th>
                                                <th className='text-left text-tablehead font-normal'>TH CO-PAY</th>
                                                <th className='text-left text-tablehead font-normal'>ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='text-[#3b3c51] font-bold text-[17.36px]'></td>
                                                <td className='text-[#3b3c51] font-bold text-[17.36px]'></td>
                                                <td className='text-[#3b3c51] font-bold text-[17.36px]'></td>
                                                <td className='text-[#3b3c51] font-bold text-[17.36px]'></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* Content for Therapy Notes Tab end*/}
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2} className="your-tab-panel-class">

                            {/* Content for Documents Tab start */}
                            <Link
                                to={`${baseUrl}/welcome/dashboard`}
                                className="text-[14px] font-bold text-white bg-primary flex gap-2 items-center px-10 rounded-xl py-1 max-w-fit mt-5 ml-auto"
                            >
                                Add Note
                            </Link>
                            <div className="overflow-x-auto">
                                <table class="table-auto w-full my-5">
                                    <thead className='border-t border-b border-gray-200'>
                                        <tr className='text-[14px] uppercase'>
                                            <th className='text-left text-tablehead font-normal'>SR.NO	</th>
                                            <th className='text-left text-tablehead font-normal'>DOCUMENT TITLE	</th>
                                            <th className='text-left text-tablehead font-normal'>DOCUMENT</th>
                                            <th className='text-left text-tablehead font-normal'>ACTION </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='text-[#3b3c51] font-bold text-[17.36px]'></td>
                                            <td className='text-[#3b3c51] font-bold text-[17.36px]'></td>
                                            <td className='text-[#3b3c51] font-bold text-[17.36px]'></td>
                                            <td className='text-[#3b3c51] font-bold text-[17.36px]'></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* Content for Documents Tab end */}

                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3} className="your-tab-panel-class">
                            <div className="overflow-x-auto">
                                {/* Content for Billing Tab Start*/}
                                <div className="overflow-x-auto">
                                    <table class="table-auto w-full my-5">
                                        <thead className='border-t border-b border-gray-200'>
                                            <tr className='text-[14px] uppercase'>
                                                <th className='text-left text-tablehead font-normal'>DATE</th>
                                                <th className='text-left text-tablehead font-normal'>THERAPIST</th>
                                                <th className='text-left text-tablehead font-normal'>DIAGNOSIS</th>
                                                <th className='text-left text-tablehead font-normal'>CO-PAY TYPE</th>
                                                <th className='text-left text-tablehead font-normal'>AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='text-[#3b3c51] font-bold text-[17.36px]'></td>
                                                <td className='text-[#3b3c51] font-bold text-[17.36px]'></td>
                                                <td className='text-[#3b3c51] font-bold text-[17.36px]'></td>
                                                <td className='text-[#3b3c51] font-bold text-[17.36px]'></td>
                                                <td className='text-[#3b3c51] font-bold text-[17.36px]'></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* Content for Billing Tab End*/}
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={4} className="your-tab-panel-class">
                            <div className="overflow-x-auto">
                                {/* Content for Notifications Tab start*/}
                                <div>
                                    <div className="flex items-center mt-4 md:mt-0">
                                        <label htmlFor="telehealth" className="text-primary font-bold text-[20px] mr-4">Telehealth:</label>
                                        {/* <FormGroup>
                                            <FormControlLabel
                                                control={<IOSSwitch checked={formData.telehealth} onChange={handleSwitchChange} />}
                                                label=""
                                            />
                                        </FormGroup> */}
                                    </div>
                                </div>
                                {/* Content for Notifications Tab end*/}
                            </div>
                        </CustomTabPanel>
                    </Box>
                </div>
                <div className="lg:col-span-4 col-span-12 rounded  lg:px-0">
                    <div className="flex flex-wrap justify-between text-primary uppercase mb-5">
                        <span className="bg-lightpurple flex items-center justify-center flex-col gap-2 text-[10px] p-5 rounded-lg w-[100px] h-[100px]">
                            <p className="font-bold">3</p>
                            <p className='text-nowrap'>OPEN NOTES</p>
                        </span>
                        <span className="bg-lightpurple flex items-center justify-center flex-col gap-2 text-[10px] p-5 rounded-lg w-[100px] h-[100px]">
                            <p className="font-bold">-</p>
                            <p className='text-nowrap'>UNPAID</p>
                        </span>
                        <span className="bg-lightpurple flex items-center justify-center flex-col gap-2 text-[10px] p-5 rounded-lg w-[100px] h-[100px]">
                            <p className="font-bold">0</p>
                            <p className='text-nowrap'>Total Hours</p>
                        </span>
                    </div>

                    <div className='flex flex-col gap-3'>
                        <Link
                            to={`${baseUrl}/welcome/dashboard`}
                            className="text-[18px] font-bold text-white bg-primary flex gap-2 items-center px-10 rounded-xl py-2 "
                        >
                            <RiCalendarScheduleLine /> Schedule Appointment

                        </Link>
                        <Link
                            to={`${baseUrl}/welcome/dashboard`}
                            className="text-[18px] font-bold text-primary bg-white flex gap-2 items-center px-10 rounded-xl py-2 border border-primary"
                        >
                            + Add Schedule
                        </Link>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ViewClientDetail
