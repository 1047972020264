import React, { useState } from 'react'

const DeleteAppointment = (props) => {
    const [deleteShow, setdeleteShow] = useState(false);
    console.log('3 : '+deleteShow);
    return (
        <>
            <div className={`fixed inset-0 bg-white z-20 pt-12 md:pt-[150px] px-3 ${deleteShow? "flex":"hidden"} justify-center items-start`}>
                <div className="border border-primary p-5 md:p-14 w-full max-w-[700px]">
                    <h3 className='text-xl font-bold text-primary font-dmsans'>
                        Are you sure you want to delete this document?
                    </h3>
                    <div className="flex gap-4 mt-9">
                        <button className='bg-[#7a65ac] text-white text-base font-bold w-32 h-10 flex items-center justify-center rounded-full opacity-90'>
                        Proceed
                        </button>
                        <button onClick={()=>setdeleteShow(false)}  className='bg-[#cfb2e7] text-white text-base font-bold w-32 h-10 flex items-center justify-center rounded-full opacity-90'>
                        Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteAppointment 