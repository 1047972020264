import React, { useState } from "react";
import Image from "../components/image/Image";
const baseUrl = "/purple-cloud";
const Login = ({ handleLogin }) => {
  const [useremail, setUseremail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(useremail, password);
  };
  return (
    <>
      <div className="max-w-[400px] mx-auto px-[25px] flex flex-col gap-2 ">
        <div className="flex items-center justify-center">
          <Image
            src="logo.svg"
            alt="Purplec123loud"
            title="Purplecloud"
            height=""
            width="340"
          />
        </div>
        <h2 className="text-primary text-[30px] leading-[40px] px-[15px] mb-3">
          Login
        </h2>
        <form onSubmit={handleSubmit} className="flex flex-col gap-3">
          <input
            type="email"
            id="email"
            name="email"
            required="required"
            className="w-full border border-lightpurple rounded-[29px] focus:border-primary h-[58px] px-5 placeholder:text-primary text-[18px] outline-primary"
            placeholder="Email Address"
            autoComplete="on"
            value={useremail}
            onChange={(e) => setUseremail(e.target.value)}
          />
          <input
            type="password"
            id="password"
            name="password"
            required="required"
            className="w-full border border-lightpurple rounded-[29px] focus:border-primary h-[58px] px-5 placeholder:text-primary outline-primary text-[18px]"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="flex gap-3">
            <button
              type="submit"
              className=" flex text-white bg-primary w-[218px] py-[15px] rounded-[29px] items-center justify-center"
            >
              Log In
            </button>
            <a
              href={`${baseUrl}/sign-up`}
              className="underline text-primary flex items-center h-[54px] w-[118px] justify-end text-[20px] font-[700]"
            >
              Sign Up
            </a>
          </div>
          <div className="mt-5">
            <a
              href={`${baseUrl}/forgot-password`}
              className="underline text-primary flex items-center h-[54px]justify-end text-[20px] font-[500] px-[30px]"
            >
              Forgot Password
            </a>
          </div>
          <div>
            <div className="filter-brightness-half flex justify-between mt-10">
              <Image
                src="hioaa.svg"
                alt="Hiipa"
                title="Hiipa"
                height="38"
                width="71"
              />
              <Image
                src="pci.svg"
                alt="PCi DSS"
                title="PCi DSS"
                height="31"
                width="82"
              />
              <Image
                src="secure.svg"
                alt="Secure SSL"
                title="Secure SSL"
                height="27"
                width="84"
              />
            </div>
            <p className="text-[#868794] text-[10px] text-center py-5">
              We take stringent administrative, physical, and technical
              precautions to guarantee that our operation and our software abide
              by all pertinent HIPAA rules. Our top goal is always keeping your
              data safe & secure.
            </p>
            <p className="text-[12px] text-primary text-center">
              Read more at{" "}
              <a href="purplecloud.io/hipaa" className="underline">
                purplecloud.io/hipaa
              </a>
            </p>
          </div>
        </form>
      </div>
      <div className="relative bg-primary min-h-[300px]">
        <div className="bg-[url(./media/Path_sec3.png)] absolute top-0 w-full left-0 right-0 bg-contain md:bg-cover bg-no-repeat bg-top h-[148px] bg-primary"></div>
      </div>
    </>
  );
};

export default Login;
