import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = ({ openSidebar }) => {
  // const [childData, setChildData] = useState(props.openSidebar);
  // props.openSidebar(childData); 
  const data = [
    "dashboard", 
    "clients", 
    "calendar", 
    "day sheet", 
    "time card", 
    "therapist", 
    "document library", 
    "insurence verification"
  ];

  const baseUrl = "/purple-cloud"; // Define the base URL

  return (
    <>
      <div className="sidebar bg-darklightgrey pb-3 h-full w-full">
        <ul className='lg:flex justify-between gap-2 flex-col pt-5 '>
          {data.map((item, index) => {
            const value = item.replace(/ /g, "-");
            return (
              <li key={index} 
              onClick={() => openSidebar(false)}
              >
                <NavLink 
                  className={({ isActive, isPending }) => 
                    isPending ? "pending" : isActive ? "active" : ""
                  } 
                  to={value === "dashboard" ? `${baseUrl}/welcome/${value}` : `${baseUrl}/${value}`}
                >
                  {item}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <div className='mx-auto flex flex-col gap-5 w-full mt-4'>
          <NavLink to={`${baseUrl}/clients/new-client`}>
            <button className='w-[253px] text-center text-white bg-primary hover:bg-light-primary mx-auto rounded-[22px] py-2 flex justify-center'>
              New Client
            </button>
          </NavLink>
          <NavLink to={`${baseUrl}/appointment`}>
            <button className='w-[253px] text-center text-white bg-primary hover:bg-light-primary mx-auto rounded-[22px] py-2 flex justify-center'>
              New Appointment
            </button>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
