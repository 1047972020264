import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Image from '../../components/image/Image';  // Assuming you have an Image component
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Autocomplete from '@mui/material/Autocomplete';  
import TextField from '@mui/material/TextField';

const baseUrl = "/purple-cloud";

function AddNote() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('clientId');
  const appId = queryParams.get('appId');

  const [clientOptions, setClientOptions] = useState({});
  const [therapistOptions, setTherapistOptions] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState({});
  const [roomOptions, setRoomOptions] = useState([]);
  const [codeOptions, setCodeOptions] = useState([]);
  const [copay, setCopay] = useState({});
  const [clientDiagnosis, setClientDiagnosis] = useState({});
  const [clientsDiagnosis, setClientsDiagnosis] = useState([]);

  const [isEditingDiagnosis, setIsEditingDiagnosis] = useState(false);
  const [diagnosisInput, setDiagnosisInput] = useState('');
  const [diagnosisOptions, setDiagnosisOptions] = useState([]);
  const [data, setData] = useState({});

  const [formData, setFormData] = useState({
    client: '',
    therapist: '',
    date: '',
    starttime: '',
    endtime: '',
    telehealth: false,
    room: '',
    reminder: '0',
    link: '',
    billingCode: '',
    copay: '',
    billingNote: '',
    sessionNote: ''
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};
    let isValid = true;
    const today = new Date().toISOString().split('T')[0];

    // Check for required fields
    if (!formData.date) {
      newErrors.date = 'Date is required';
      isValid = false;
    }
    if (formData.starttime && formData.endtime) {
      if (formData.starttime >= formData.endtime) {
        newErrors.starttime = 'End time must be later than start time';
        isValid = false;
      }
    }
    if (!formData.billingCode) {
      newErrors.billingCode = 'Billing Code is required';
      isValid = false;
    }
    if (!formData.copay) {
      newErrors.copay = 'Copay is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    const fetchAppointmentData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`https://sandbox.purplecloud.io/api/note`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token, clientid: id, appId: appId }),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        console.log('API Response:', data);
    
       /*  const diagnosisOptions = Object.entries(data).map(([id, label]) => ({ id, label }));
        console.log('Diagnosis Options:', diagnosisOptions);
        setDiagnosisOptions(diagnosisOptions); */
       
    
      
        setData(data);
        setClientOptions(data.cDetail[0] || {});
        setAppointmentDetails(data.appointment_details || {});
        setCodeOptions(data.code || []);
        setCopay(data.insurance_copay || {});
        setClientsDiagnosis(data.clients_diagnosis || []);
        setClientDiagnosis(data.client_diagnosis || {});
        setDiagnosisOptions(data.diagnosis_autocomplete || []);
      } catch (err) {
        console.error('Failed to fetch appointment data:', err);
        alert('Failed to fetch appointment data.');
      }
    };
    
    fetchAppointmentData();
  }, [id, appId]);

  const handleDiagnosisSelect = (event, newValue) => {
    if (newValue) {
      console.log('Selected Diagnosis:', newValue);
      setIsEditingDiagnosis(false);
      setFormData(prevFormData => ({
        ...prevFormData,
        diagnosis: newValue.id // Assuming you want to store the selected diagnosis ID
      }));
    }
  };

  const handleDiagnosisInputChange = (event, newInputValue) => {
    setDiagnosisInput(newInputValue);
  };

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#7A65AC',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      telehealth: e.target.checked
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`https://sandbox.purplecloud.io/api/appointment/edit_appointment`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            token,
            appid: id,  // Appointment ID
            ...formData
          }),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        alert('Appointment updated successfully!');

        navigate(`${baseUrl}/welcome/dashboard`);
      } catch (err) {
        console.error('Failed to update appointment:', err);
        alert('Failed to update appointment.');
      }
    }
  };

  const getTime = (timeString) => {
    const date = new Date(timeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const handleDiagnosisClick = () => {
    setIsEditingDiagnosis(true);
  };

  const handleDiagnosisInputBlur = () => {
    // Optionally, handle input blur here to save the diagnosis
    setIsEditingDiagnosis(false);
  };

  return (
    <>
      <div>
        <div className='flex items-center justify-between bg-darklightgrey'>
          <div className='container mx-auto flex justify-between py-[20px] px-[40px]'>
            <h3 className='text-black text-[20px] md:text-[40px] font-bold'>
              {clientOptions.lname}, {clientOptions.fname} &nbsp;&nbsp;
              {appointmentDetails.appDate ? (
                <span className='text-primary'>Note {appointmentDetails.appDate}</span>
              ) : (
                <span className='text-primary'>No Date Available</span>
              )}
            </h3>
            <div className='flex items-center gap-5'>
              <button className='bg-primary text-white w-[150px] md:w-[300px] md:flex hidden items-center justify-center py-[15px] rounded-[60px] font-bold text-[23px]' onClick={handleSubmit}>SAVE</button>
              <Link to={`${baseUrl}/welcome/dashboard`} className='text-[20px] font-bold text-primary'>
                <Image src="common/cross-sign.png" alt="logo" width="35" height="30" />
              </Link>
            </div>
          </div>
        </div>
        <div className='container mx-auto md:mx-0 p-[20px]'>
          <div className='flex flex-wrap lg:px-6 py-2 gap-2'>
            {/* Render clientDiagnosis */}
            {clientDiagnosis && (
              <span className='w-auto bg-primary text-white rounded-lg px-2 py-1 cursor-pointer font-bold flex gap-2 items-center'>
                {clientDiagnosis.title} {clientDiagnosis.description !== clientDiagnosis.title ? clientDiagnosis.description : ''}
                <Link to="#" className='text-[20px] font-bold text-primary' data-id={clientDiagnosis.uid}>
                  <Image src="common/cross-sign.png" alt="logo" width="10" height="10" />
                </Link>
              </span>
            )}

            {clientsDiagnosis
              .filter((diagnosis) => diagnosis.uid !== clientDiagnosis?.uid)
              .map((diagnosis) => (
                <span
                  key={diagnosis.uid} // Ensure `uid` is unique
                  className='w-auto bg-[#efeaf8] text-primary rounded-lg px-2 py-1 cursor-pointer font-bold flex gap-2 items-center'
                >
                  {diagnosis.title} {diagnosis.description !== diagnosis.title ? diagnosis.description : ''}
                  <Link to="#" className='text-[20px] font-bold text-primary' data-id={diagnosis.uid}>
                    <Image src="common/cross-sign.png" alt="logo" width="10" height="10" />
                  </Link>
                </span>
              ))}

            {isEditingDiagnosis ? (
             <Autocomplete
             freeSolo
             options={(data.diagnosis_autocomplete || []).map((item, index) => ({
               ...item,
               key: item.id || index // Ensure each key is unique
             }))}
             getOptionLabel={(option) => option.title || ''}
             value={formData.diagnosis}
             onChange={handleDiagnosisSelect}
             className='w-[250px]'
             renderInput={(params) => (
               <TextField
                 {...params}
                 label="Select or Type Diagnosis"
                 variant="outlined"
                 fullWidth
                 InputProps={{
                   ...params.InputProps,
                   style: { borderRadius: '8px' }
                 }}
               />
             )}
           />
           
           
            ) : (
              <span
                className='border rounded-xl px-2 border-dashed flex items-center justify-center text-lightpurple border-lightpurple cursor-pointer'
                onClick={handleDiagnosisClick}
              >
                Diagnosis
              </span>
            )}
          </div>

          <div className='grid grid-cols-12 gap-4 lg:px-6'>
            <div className='col-span-6'>
              <span className='text-primary font-bold'>Diagnosis:</span>
            </div>
            <div className='col-span-6'>
              <span className='text-primary font-bold'>Telehealth: Open Link</span>
            </div>
          </div>
          <div className="lg:p-6">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-4">
                  <label htmlFor="billingCode" className="block text-primary font-bold text-[20px]">Billing Code:</label>
                  <select
                    name="billingCode"
                    id="billingCode"
                    className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                    value={formData.billingCode}
                    onChange={handleChange}
                  >
                    <option value="">Select Billing Code</option>
                    {codeOptions.map((code) => (
                      <option key={code.uid} value={code.uid}>
                        {code.title}
                      </option>
                    ))}
                  </select>
                  {errors.billingCode && <span className="text-red-500">{errors.billingCode}</span>}
                </div>
                <div className="col-span-4">
                  <label htmlFor="copay" className="block text-primary font-bold text-[20px]">Co-Pay:<span className='font-bold text-black'>${copay.co_pay}</span></label>
                  <select
                    name="copay"
                    id="copay"
                    className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                    value={formData.copay}
                    onChange={handleChange}
                  >
                    <option value="Cash">Cash</option>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Check">Check</option>
                    <option value="Bill Co-Pay">Bill Co-Pay</option>
                    <option value="Other">Other</option>
                  </select>

                  {errors.copay && <span className="text-red-500">{errors.copay}</span>}
                </div>
                <div className="col-span-2">
                  <label htmlFor="starttime" className="block text-primary font-bold text-[20px]">Start Time:</label>
                  <input
                    type="time"
                    name="starttime"
                    id="starttime"
                    className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                    value={formData.starttime}
                    onChange={handleChange}
                  />
                  {errors.starttime && <span className="text-red-500">{errors.starttime}</span>}
                </div>
                <div className="col-span-2">
                  <label htmlFor="endtime" className="block text-primary font-bold text-[20px]">End Time:</label>
                  <input
                    type="time"
                    name="endtime"
                    id="endtime"
                    className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                    value={formData.endtime}
                    onChange={handleChange}
                  />
                  {errors.endtime && <span className="text-red-500">{errors.endtime}</span>}
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <label htmlFor="billing-note" className="block text-primary font-bold text-[20px]">Billing Note:</label>
                  <textarea
                    name="billingNote"
                    id="billing-note"
                    className='mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] focus:border-indigo-300 focus:outline-none focus:ring-0 p-5 text-primary font-bold'
                    rows={6}
                    value={formData.billingNote}
                    onChange={handleChange}
                  />
                  {errors.billingNote && <span className="text-red-500">{errors.billingNote}</span>}
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <label htmlFor="session-note" className="block text-primary font-bold text-[20px]">Session Note:</label>
                  <textarea
                    name="sessionNote"
                    id="session-note"
                    className='mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] focus:border-indigo-300 focus:outline-none focus:ring-0 p-5 text-primary font-bold'
                    rows={10}
                    value={formData.sessionNote}
                    onChange={handleChange}
                  />
                  {errors.sessionNote && <span className="text-red-500">{errors.sessionNote}</span>}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNote;
