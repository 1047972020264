import React, { useState, useEffect } from 'react';
import DateOfBirthPicker from '../../components/datepicker/DateOfBirthPicker';
import { useParams, useNavigate } from 'react-router-dom';
import Image from '../../components/image/Image';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Link } from 'react-router-dom';

const baseUrl = "/purple-cloud";

function Appointment() {
  const { id } = useParams(); // Get the appointment ID from the URL
  const [clientOptions, setClientOptions] = useState([]);
  const [therapistOptions, setTherapistOptions] = useState([]);
  const [RoomOptions, setRoomOptions] = useState([]);
  const [formData, setFormData] = useState({
    client: '',
    therapist: '',
    date: new Date().toISOString().split('T')[0],
    starttime: '12:00:00',
    endtime: '12:00:00',
    telehealth: false,
    room: '',
    reminder: '0',
    link: ''
  });
  const [errors, setErrors] = useState({
    client: '',
    starttime: '',
    endtime: '',
    timeRange: '',
    link: '',
    date: ''
  });
  const navigate = useNavigate();

  const handleDobChange = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      date: date, // Assuming date is already the selected date value
    }));
  };

  useEffect(() => {
    const fetchAppointmentData = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`https://sandbox.purplecloud.io/api/appointment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }), // Send token in body
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setClientOptions(data.data.client || []);
        setTherapistOptions(data.data.therapist || []);
        setRoomOptions(data.data.arrRooms || []);

        // Set form data if available
        setFormData({
          client: data.data.client_id || '',
          therapist: data.data.therapist_id || '',
          date: data.data.date || new Date().toISOString().split('T')[0],
          starttime: data.data.starttime || '12:00:00',
          endtime: data.data.endtime || '12:00:00',
          telehealth: data.data.telehealth || false,
          room: data.data.room || '',
          reminder: data.data.reminder || '0',
          link: data.data.link || ''
        });
      } catch (err) {
        console.error('Failed to fetch appointment data:', err);
        alert('Failed to fetch appointment data.');
      }
    };

    fetchAppointmentData();
  }, [id]);

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#7A65AC',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSwitchChange = (e) => {
    setFormData(prevState => ({
      ...prevState,
      telehealth: e.target.checked ? 1 : 0
    }));
  };

  const validate = () => {
    let isValid = true;
    let errors = {};

    const today = new Date().toISOString().split('T')[0];
    const selectedDate = new Date(formData.date).toISOString().split('T')[0];
    const currentTime = new Date().toTimeString().split(' ')[0].slice(0, 5); 

    if (!formData.client) {
      errors.client = 'Please select a client.';
      isValid = false;
    }

    

    if (formData.date === today && formData.starttime < currentTime) {

      errors.starttime = 'You cannot book a time earlier than the current time.';
      isValid = false;
    }
    if (formData.starttime && formData.endtime && formData.starttime > formData.endtime) {
      errors.timeRange = 'Start time cannot be later than end time.';
      isValid = false;
    }

    if (formData.telehealth === 1 && !formData.link) {
      errors.link = 'Telehealth link is required';
      isValid = false;
    }



    if (selectedDate < today) {
      errors.date = 'You cannot select a past date.';
      isValid = false;
    }


    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form data
    if (!validate()) {
      return; // If validation fails, stop form submission
    }

    const token = localStorage.getItem('token');
    const dataToSend = {
      ...formData,
      token, // Add token to the data to send
    };

    try {
      const response = await fetch(`https://sandbox.purplecloud.io/api/appointment/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend), // Convert the object to a JSON string
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Response data:', data);

      if (data.status === 'success') {
        alert('Appointment scheduled successfully!');
        navigate(`${baseUrl}/appointment`);
      } else {
        alert('Failed to schedule the appointment.');
      }

    } catch (err) {
      console.error('Failed to submit the form:', err);
      alert('Failed to submit the form.');
    }
  };

  return (
    <>
      <div className=''>
        <div className='flex items-center justify-between bg-darklightgrey'>
          <div className='container mx-auto flex justify-between py-[20px] px-[40px]'>
            <h3 className='text-black text-[20px] md:text-[40px] font-bold'>Schedule Appointment</h3>
            <div className='flex items-center gap-5'>
              <button className='bg-primary text-white w-[150px] md:w-[300px] md:flex hidden items-center justify-center py-[15px] rounded-[60px] font-bold text-[23px]' onClick={handleSubmit}>Save</button>
              <Link to={`${baseUrl}/welcome/dashboard`} className='text-[20px] font-bold text-primary'><Image src="common/cross-sign.png" alt="logo" width="35" height="30" /></Link>
            </div>
          </div>
        </div>
        <div className='container mx-auto md:mx-0 w-[100%] md:w-[50%] p-[20px]'>
          <div className="lg:p-6">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <label htmlFor="client" className="block text-primary font-bold text-[20px]">Client:</label>
                  <select
                    id="client"
                    name="client"
                    value={formData.client}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                  >
                    <option value="">Select Client</option>
                    {clientOptions.map(client => (
                      <option key={client.client_id} value={client.client_id}>{client.lname}, {client.fname} </option>
                    ))}
                  </select>
                  {errors.client && <p className="text-red-500 text-sm">{errors.client}</p>}
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <label htmlFor="therapist" className="block text-primary font-bold text-[20px]">Therapist:</label>
                  <select
                    id="therapist"
                    name="therapist"
                    value={formData.therapist}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                  >
                    {therapistOptions.map(therapist => (
                      <option key={therapist.doc_id} value={therapist.client_id}>{therapist.lname}, {therapist.fname} </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <DateOfBirthPicker
                    label="Date:"
                    pickerId="date"
                    name="date"

                    selectedDate={formData.date}
                    onChange={handleDobChange}
                    min={new Date().toISOString().split('T')[0]}

                  />
                  {errors.date && <p className="text-red-500 text-sm">{errors.date}</p>}
                </div>

                <div className="flex-1 mt-4 md:mt-0">
                  <label htmlFor="starttime" className="block text-primary font-bold text-[20px]">Start Time:</label>
                  <input
                    type="time"
                    id="starttime"
                    name="starttime"
                    value={formData.starttime}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"

                  />
                   {errors.starttime && (<p className="text-red-500 text-sm">{errors.starttime}</p>)}

                </div>
                <div className="flex-1 mt-4 md:mt-0">
                  <label htmlFor="endtime" className="block text-primary font-bold text-[20px]">End Time:</label>
                  <input
                    type="time"
                    id="endtime"
                    name="endtime"
                    value={formData.endtime}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                  />

                  {errors.timeRange && <p className="text-red-500 text-sm">{errors.timeRange}</p>}
                </div>
              </div>
              <div className="flex items-center mt-4 md:mt-0">
                <div className="flex-1">
                  <label htmlFor="telehealth" className="text-primary font-bold text-[20px] mr-4">Telehealth:</label>
                  <FormGroup>
                    <FormControlLabel
                      control={<IOSSwitch checked={formData.telehealth === 1} onChange={handleSwitchChange} />}
                      label="" className='mt-5 pl-5' name="telehealth"
                    />
                  </FormGroup>
                </div>
                {formData.telehealth ? (
                  <div className='flex-1'>
                    <label htmlFor="reminder" className="block text-primary font-bold text-[20px]">Telehealth Link:</label>
                    <input
                      type="text"
                      id="link"
                      name="link"
                      value={formData.link}
                      onChange={handleChange}
                      placeholder='Please paste your telehealth meeting link here.'
                      className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                    />
                    {errors.link && <p className="text-red-500 text-sm">{errors.link}</p>}
                  </div>
                ) : (
                  <div className="flex-1">
                    <label htmlFor="room" className="block text-primary font-bold text-[20px]">Room:</label>
                    <select
                      id="room"
                      name="room"
                      value={formData.room}
                      onChange={handleChange}
                      className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                    >
                      {RoomOptions.map(room => (
                        <option key={room.room_uid} value={room.room_uid}>{room.title} </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>

              <div className="flex flex-col md:flex-row md:space-x-4">

                <div className="flex-1 mt-4 md:mt-0">
                  <label htmlFor="reminder" className="block text-primary font-bold text-[20px]">Reminder:</label>
                  <select
                    id="reminder"
                    name="reminder"
                    value={formData.reminder}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                  >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Appointment;
