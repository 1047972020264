import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

// layout
import Header from './layout/header/Header';
import Sidebar from './layout/sidebar/Sidebar';

// components
import Preview from './components/Daysheet/Preview';

// admin
import Login from './admin/Login';
import SignUp from './admin/SignUp';
import ForgotPassword from './admin/ForgotPassword';

// pages
import {
  AddDocument,
  Appointment,
  MyCalendar,
  Clients,
  Dashboard,
  Daysheet,
  DocumentLibrary,
  InsurenceVerification,
  NewClient,
  UpdateClient,
  NewTherapist,
  Therapist,
  Timecard,
  UpdateAppointment,
  UpdateDaysheet,
  UpdateInsurenceVerification,
  UpdateTherapist,
  ViewClientDetail,
  Diagnostic, 
  TimecardDetail,
  AddNote,
  
} from './pages';

const baseUrl = "/purple-cloud";

function App() {
  const [sidebar, setSidebar] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  // isLoggedIn state from local storage
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem("isLoggedIn") === "true";
  });

  useEffect(() => {
    localStorage.setItem("isLoggedIn", isLoggedIn);
  }, [isLoggedIn]);

  // List of paths where the sidebar and header should not be displayed
  const noSidebarAndHeaderPaths = [
    `${baseUrl}/login`,
    `${baseUrl}/sign-up`,
    `${baseUrl}/forgot-password`,
  ];

  const noSidebarPaths = [
    `${baseUrl}/clients/new-client`,
    `${baseUrl}/new-client`,
    `${baseUrl}/appointment`,
    `${baseUrl}/therapist/update`,
    `${baseUrl}/therapist/create-team`,
    `${baseUrl}/clients/update`,
    `${baseUrl}/diagnostic/add`,
    `${baseUrl}/note`,
  ];

  const isNoSidebarAndHeaderPath = noSidebarAndHeaderPaths.some((path) =>
    location.pathname.startsWith(path)
  );
  const isNoSidebarPath = noSidebarPaths.some((path) =>
    location.pathname.startsWith(path)
  );
  const isUpdateAppointment = location.pathname.match(
    /\/appointment\/update_appointment\/\d+/
  );
  const isTherapistUpdate = location.pathname.match(/\/therapist\/update\/\d+/);
  const isTherapistCreateTeam = location.pathname.match(
    /\/therapist\/create-team\//
  );

  const shouldDisplaySidebar = !(
    isNoSidebarAndHeaderPath ||
    isNoSidebarPath ||
    isUpdateAppointment ||
    isTherapistUpdate ||
    isTherapistCreateTeam
  );
  const shouldApplyPadding = shouldDisplaySidebar;

  // handleLogin
  const handleLogin = async (useremail, password) => {
    try {
      const response = await fetch('https://sandbox.purplecloud.io/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          email: useremail,
          password: password,
        }),
      });

      const result = await response.json();
      console.log("API Response:", result);

      if (result.status === 'success') {
        localStorage.setItem('token', result.token);
        setIsLoggedIn(true);
        alert("Login successful");
        navigate(`${baseUrl}/welcome/dashboard`);
      } else {
        alert("Invalid credentials");
      }
    } catch (error) {
      console.error("Login error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  // handleLogout
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');

      if (token) {
        const response = await fetch('https://sandbox.purplecloud.io/api/logout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            token: token
          })
        });

        const result = await response.json();
        console.log("Logout API Response:", result);

        if (result.status === 'success') {
          alert("Logged out successfully.");
          setIsLoggedIn(false);
          localStorage.removeItem('token');
          navigate(`${baseUrl}/login`);
        } else {
          alert(`Logout failed: ${result.message}`);
        }
        } else {
          alert("No token found. Please log in again.");
        }
    } catch (error) {
      console.error("Logout API Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div
      className={
        isNoSidebarAndHeaderPath
          ? ""
          : "font-dmsans h-full grid grid-cols-1 xl:grid-cols-[330px_1fr] grid-rows-[72px_1fr]"
      }
    >
      {!isNoSidebarAndHeaderPath && (
        <div className="xl:col-span-2">
          <Header openMenu={setSidebar} handleLogout={handleLogout} />
        </div>
      )}

      {shouldDisplaySidebar && (
        <div
          className={`xl:h-full overflow-auto bg-white fixed xl:relative z-10 left-0 top-[72px] xl:top-0 h-[calc(100%_-_72px)] ${
            !sidebar ? "-translate-x-full" : "translate-x-0"
          } xl:translate-x-0 duration-700`}
        >
          <Sidebar openSidebar={setSidebar} />
        </div>
      )}

      <div
        className={`overflow-auto ${shouldDisplaySidebar ? "" : "col-span-2"}`}
      >
        <div
          className={
            shouldApplyPadding ? "md:py-[30px] px-3 py-[20px]" : undefined
          }
        >
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  replace
                  to={
                    isLoggedIn
                      ? `${baseUrl}/welcome/dashboard`
                      : `${baseUrl}/login`
                  }
                />
              }
            />
            <Route
              path={`${baseUrl}/login`}
              element={<Login handleLogin={handleLogin} />}
            />
            <Route path={`${baseUrl}/sign-up`} element={<SignUp />} />
            <Route path={`${baseUrl}/forgot-password`} element={<ForgotPassword />} />
            
            {isLoggedIn ? (
              <>
                <Route path={`${baseUrl}/welcome/dashboard`} element={<Dashboard />} />
                <Route path={`${baseUrl}/clients`} element={<Clients />} />
                <Route path={`${baseUrl}/calendar`} element={<MyCalendar />} />
                <Route path={`${baseUrl}/clients/new-client`} element={<NewClient />} />
                <Route path={`${baseUrl}/appointment`} element={<Appointment />} />
                <Route path={`${baseUrl}/day-sheet`} element={<Daysheet />} />
                <Route path={`${baseUrl}/day-sheet/:id`} element={<Preview />} />
                <Route path={`${baseUrl}/day-sheet/preview/:id`} element={<UpdateDaysheet />} />
                <Route path={`${baseUrl}/appointment/update_appointment/:id`} element={<UpdateAppointment />} />
                <Route path={`${baseUrl}/clients/client-details/:clientId`} element={<ViewClientDetail />} />
                <Route path={`${baseUrl}/clients/update/:clientId`} element={<UpdateClient />} />
                <Route path={`${baseUrl}/diagnostic/add/:clientId`} element={<Diagnostic />} />
                <Route path={`${baseUrl}/therapist/update/:therapistId`} element={<UpdateTherapist />} />
                <Route path={`${baseUrl}/therapist/create-team`} element={<NewTherapist />} />
                <Route path={`${baseUrl}/time-card`} element={<Timecard />} />
                <Route path={`${baseUrl}/timecard-detail`} element={<TimecardDetail />} /> 
                <Route path={`${baseUrl}/therapist`} element={<Therapist />} />
                <Route path={`${baseUrl}/document-library`} element={<DocumentLibrary />} />
                <Route path={`${baseUrl}/insurence-verification`} element={<InsurenceVerification />} />
                <Route path={`${baseUrl}/insurence-verification/:ins_id`} element={<UpdateInsurenceVerification />} />
                <Route path={`${baseUrl}/document-library/add`} element={<AddDocument />} />
                <Route path={`${baseUrl}/note`} element={<AddNote />} />

              </>
            ) : (
              <Route path="*" element={<Navigate to={`${baseUrl}/login`} />} />
            )}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
