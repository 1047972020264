import React from 'react'

import Image from "../components/image/Image";
const Foot = () => {
    return (
        <>
            <div className='max-w-[400px] mx-auto'>
                <div className="filter-brightness-half flex justify-between mt-10 ">
                    <Image
                        src="hioaa.svg"
                        alt="Hiipa"
                        title="Hiipa"
                        height="38"
                        width="71"
                    />
                    <Image
                        src="pci.svg"
                        alt="PCi DSS"
                        title="PCi DSS"
                        height="31"
                        width="82"
                    />
                    <Image
                        src="secure.svg"
                        alt="Secure SSL"
                        title="Secure SSL"
                        height="27"
                        width="84"
                    />
                </div>
                <p className="text-[#868794] text-[10px] text-center py-5">
                    We take stringent administrative, physical, and technical
                    precautions to guarantee that our operation and our software abide
                    by all pertinent HIPAA rules. Our top goal is always keeping your
                    data safe & secure.
                </p>
                <p className="text-[12px] text-primary text-center">
                    Read more at{" "}
                    <a href="purplecloud.io/hipaa" className="underline">
                        purplecloud.io/hipaa
                    </a>
                </p>
            </div>

            <div className="relative bg-primary min-h-[300px]">
                <div className="bg-[url(./media/Path_sec3.png)] absolute top-0 w-full left-0 right-0 bg-contain md:bg-cover bg-no-repeat bg-top h-[148px] bg-primary"></div>
            </div>
        </>
    )
}

export default Foot