import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DateOfBirthPicker from '../../components/datepicker/DateOfBirthPicker';
import Image from '../../components/image/Image';

const baseUrl = "/purple-cloud";
const apiUrl = 'https://sandbox.purplecloud.io/api/update-client';
const addClientUrl = 'https://sandbox.purplecloud.io/api/edit-client';

const UpdateClient = () => {
  const { clientId } = useParams();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    ssn: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    homephone: '',
    cellphone: '',
    email: '',
    assignTherapist: [],
    status: '1',
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [therapists, setTherapists] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token, ClientId: clientId }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Fetched data:', data);

        if (data && data.data) {
          if (data.data.client && data.data.client.length > 0) {
            const client = data.data.client[0];
            setFormData({
              firstName: client.fname || '',
              lastName: client.lname || '',
              dob: client.dob || '',
              ssn: client.snn || '',
              address: client.address || '',
              city: client.city || '',
              state: client.state || '',
              zip: client.zipcode || '',
              homephone: client.mob1 || '',
              cellphone: client.mob2 || '',
              email: client.eml || '',
              assignTherapist: client.assignTherapist || [],
              status: client.status || '1',
            });

            if (data.data.therapist) {
              setTherapists(data.data.therapist);
            }
          } else {
            setError('No client data found.');
          }
        } else {
          setError('Invalid response format.');
        }
      } catch (err) {
        setError('Failed to fetch client details.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchClientData();
  }, [clientId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const { assignTherapist } = formData;
    if (checked) {
      setFormData({ ...formData, assignTherapist: [...assignTherapist, value] });
    } else {
      setFormData({
        ...formData,
        assignTherapist: assignTherapist.filter((therapist) => therapist !== value),
      });
    }
  };

  const handleValidation = () => {
    const fields = ['firstName', 'lastName', 'address', 'city', 'state', 'zip'];
    const errors = {};
    fields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = `${field.replace(/([A-Z])/g, ' $1').trim()} is required.`;
      }
    });
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleDobChange = (date) => {
    setFormData({ ...formData, dob: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setLoading(true);
      const token = localStorage.getItem('token');
      const dataToSend = {
        ...formData,
        status: Number(formData.status),
        token,
        ClientId: clientId,
      };

      try {
        const response = await fetch(addClientUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataToSend),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Response data:', data);

        if (data.status === 'success') {
          alert('Client updated successfully!');
          navigate(`${baseUrl}/clients`);
        } else {
          alert('Failed to update client.');
        }
      } catch (err) {
        setError('Failed to submit the form.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };
 
  return (
    <>
      <div>
        <div className='flex items-center justify-between bg-darklightgrey'>
          <div className='container mx-auto flex justify-between py-[20px] px-[40px]'>
            <h3 className='text-black text-[20px] md:text-[40px] font-bold'>Update Client</h3>
            <div className='flex items-center gap-5'>
            <button
               onClick={handleSubmit}
                className='bg-primary text-white w-[150px] md:w-[300px] md:flex hidden items-center justify-center py-[15px] rounded-[60px] font-bold text-[23px]'
              >
                Save
              </button>
              <Link to={`${baseUrl}/clients`} className='text-[20px] font-bold text-primary'>
                <Image src="common/cross-sign.png" alt="logo" width="35" height="30" />
              </Link>
            </div>
          </div>
        </div>
        <div className='container mx-auto md:mx-0 w-[100%] md:w-[80%] p-[20px]'>
          <div className="lg:p-6">
            <form className="space-y-6" action={handleSubmit} >
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <label htmlFor="firstName" className="block text-primary font-bold text-[20px]">First Name:</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                  />
                  {errors.firstName && <span className="text-red-500">{errors.firstName}</span>}
                </div>
                <div className="flex-1 mt-4 md:mt-0">
                  <label htmlFor="lastName" className="block text-primary font-bold text-[20px]">Last Name:</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                  />
                  {errors.lastName && <span className="text-red-500">{errors.lastName}</span>}
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                <DateOfBirthPicker
                    label="DOB:"
                    pickerId="dob"
                    name="dob"
                    selectedDate={formData.dob}
                    onChange={handleDobChange}
                  />
                </div>
                <div className="flex-1 mt-4 md:mt-0">
                  <label htmlFor="ssn" className="block text-primary font-bold text-[20px]">SSN:</label>
                  <input
                    type="text"
                    id="ssn"
                    name="ssn"
                    value={formData.ssn}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="address" className="block text-primary font-bold text-[20px]">Address:</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                />
                {errors.address && <span className="text-red-500">{errors.address}</span>}
              </div>

              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <label htmlFor="city" className="block text-primary font-bold text-[20px]">City:</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                  />
                  {errors.city && <span className="text-red-500">{errors.city}</span>}
                </div>
                <div className="flex-1 mt-4 md:mt-0">
                  <label htmlFor="state" className="block text-primary font-bold text-[20px]">State:</label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                  />
                  {errors.state && <span className="text-red-500">{errors.state}</span>}
                </div>
                <div className="flex-1 mt-4 md:mt-0">
                  <label htmlFor="zip" className="block text-primary font-bold text-[20px]">Zip:</label>
                  <input
                    type="number"
                    id="zip"
                    name="zip"
                    value={formData.zip}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                    
                  />
                  {errors.zip && <span className="text-red-500">{errors.zip}</span>}
                </div>
              </div>

              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <label htmlFor="homephone" className="block text-primary font-bold text-[20px]">Home Phone:</label>
                  <input
                    type="number"
                    id="homephone"
                    name="homephone"
                    value={formData.homephone}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                  />
                </div>
                <div className="flex-1 mt-4 md:mt-0">
                  <label htmlFor="cellphone" className="block text-primary font-bold text-[20px]">Cell Phone:</label>
                  <input
                    type="number"
                    id="cellphone"
                    name="cellphone"
                    value={formData.cellphone}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <label htmlFor="email" className="block text-primary font-bold text-[20px]">Email Address:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                  />
                </div>
                <div className="flex-1 mt-4 md:mt-0">
                  <div className='flex gap-3'>
                    <div className='flex-1 mt-4 md:mt-0'>
                      <label htmlFor="assign_therapist" className="block text-primary font-bold text-[20px]">Assign Therapist:</label>
                      <div className="mt-1 flex flex-col">
                        <select
                          id="assign_therapist"
                          name="assignTherapist"
                          value={formData.assignTherapist}
                          onChange={handleChange}
                          className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                          multiple
                        >
                          {therapists.map((therapist) => (
                            <option key={therapist.doc_id} value={therapist.doc_id}>
                              <label className="inline-flex items-center">
                                <input
                                  type="checkbox"
                                  value={therapist.doc_id}
                                  onChange={handleCheckboxChange}
                                  className="form-checkbox h-5 w-5 text-primary"
                                />
                                <span className="ml-2 text-primary font-bold">{`${therapist.fname} ${therapist.lname}`}</span>
                              </label>
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='flex-1 mt-4 md:mt-0'>
                      <label htmlFor="status" className="block text-primary font-bold text-[20px]">Status:</label>
                      <select
                        id="status"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                      >
                       
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateClient;
