import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Image from '../../components/image/Image';


const baseUrl = "/purple-cloud";

function AddDocument() {
    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);
    const [errors, setErrors] = useState({ title: '', file: '' });
    const [loading, setLoading] = useState(false); // Define setLoading
    const [error, setError] = useState(''); // Define setError
    const navigate = useNavigate(); // Use useNavigate for navigation

    const validateForm = () => {
        let isValid = true;
        const newErrors = { title: '', file: '' };

        if (!title.trim()) {
            newErrors.title = 'Document Title is required';
            isValid = false;
        }

        if (!file) {
            newErrors.file = 'Document file is required';
            isValid = false;
        } else if (!['gif', 'jpg', 'jpeg', 'png', 'pdf', 'docx', 'doc', 'xlsx'].includes(file.type.split('/').pop())) {
            newErrors.file = 'Invalid file type';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('token', token);
            formData.append('title', title);
            formData.append('document', file);

            try {
                const response = await fetch('https://sandbox.purplecloud.io/api/document-library/add', {
                    method: 'POST',
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log('Response data:', data);

                alert('Document uploaded successfully!');
                navigate(`${baseUrl}/document-library`);

            } catch (err) {
                setError('Failed to submit the form.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <div className='flex justify-between flex-col items-start'>
                <div className='container mx-auto flex justify-between py-[10px] px-[10px] lg:px-[0px]'>
                    <h3 className='text-black text-[20px] md:text-[40px] font-bold'>Upload New Document</h3>
                    <div className='flex items-center gap-5'>
                        <button
                            className='bg-primary text-white w-[150px] md:w-[300px] md:flex hidden items-center justify-center py-[15px] rounded-[60px] font-bold text-[23px]'
                            onClick={handleSubmit}
                            disabled={loading} 
                        >
                            {loading ? 'Saving...' : 'Save'} 
                        </button>
                        <Link to={`${baseUrl}/document-library`} className='text-[20px] font-bold text-primary'>
                            <Image src="common/cross-sign.png" alt="logo" width="35" height="30" />
                        </Link>
                    </div>
                </div>
                <div className="container w-[100%] md:w-[50%]">
                    <div className="lg:py-6 lg:px-5 py-3">
                        <form className="space-y-6">
                            <div className="flex flex-col md:flex-row md:space-x-4">
                                <div className="flex-1">
                                    <label htmlFor="title" className="block text-primary font-bold text-[20px]">Document Title:</label>
                                    <input
                                        type="text"
                                        id="title"
                                        name="title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        className={`mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border ${errors.title ? 'border-lightpurple' : 'border-lightpurple'} focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary`}
                                        autoComplete='off'
                                    />
                                    {errors.title && <p className='text-red-500 text-[12px]'>{errors.title}</p>}
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row md:space-x-4 justify-start items-start">
                                <div className="flex-1">
                                    <input
                                        type="file"
                                        id="profileImage"
                                        name="profileImage"
                                        onChange={(e) => setFile(e.target.files[0])}
                                        className="hidden"
                                    />
                                    <div className='flex gap-2 items-center'>
                                        <label htmlFor="profileImage" className="block text-primary font-bold text-[20px]">Document:</label>
                                        <span className='text-[12px] text-black'>Allowed file type: gif, jpg, png, jpeg, pdf, docx, doc, xlsx</span>
                                    </div>
                                    <label htmlFor="profileImage" className="text-primary font-bold text-[20px] cursor-pointer flex mt-5">
                                        <figure className="flex flex-col items-start">
                                            <svg width="40" height="34" viewBox="0 0 20 17" className="mb-2" fill="#7A65AC">
                                                <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                                            </svg>
                                            Attach Document
                                        </figure>
                                    </label>
                                    {errors.file && <p className='text-red-500 text-[12px]'>{errors.file}</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddDocument;
