import React from 'react'

import Image from "../components/image/Image";
const Head = (props) => {
  return (
    <>
    
        <div className="flex items-center justify-center">
                    <Image
                        src="logo.svg"
                        alt="Purplec123loud"
                        title="Purplecloud"
                        height=""
                        width="340"
                    />
                </div>
                <h3 className="text-primary text-[30px] leading-[40px] mb-3">
                   {props.title}
                </h3>
                <p className='text-primary text-sm'>
                    {
                        props.docs
                    }
                </p>
    </>
  )
}

export default Head