import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Image from '../../components/image/Image';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

const baseUrl = "/purple-cloud";
const addDiagnosticUrl = 'https://sandbox.purplecloud.io/api/diagnostic/add';
const fetchDataUrl = 'https://sandbox.purplecloud.io/api/diagnostic/data';

const Diagnostic = () => {
    const token = localStorage.getItem('token');
    const { clientId } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [dignosticData, setdignosticData] = useState({
        ideations: {},
        risks: {}
    });
    const [formState, setFormState] = useState({
        memory: '',
        attention: '',
        orientation: '',
        appearance: '',
        affect: '',
        motor: '',
        thought_process: '',
        insight: '',
        judgment: '',
        impulse_control: '',
        concentration: '',
        behavior: '',
        thought_disorder: '',
        care_physician: '',
        c_medication: '',
        ideations: {
            suicidal: '',
            none_suicidal: '',
            thoughts: '',
            plan: '',
            intent: '',
            means: '',
            homicidal: '',
            none_homicidal: '',
            homicidal_thoughts: '',
            homicidal_plan: '',
            homicidal_intent: '',
            homicidal_means: ''
        },
        risks: {
            nonCompliance: '',
            domesticViolence: '',
            childAbuse: '',
            sexualAbuse: '',
            suicidalIdeation: '',
            eatingDisorder: '',
            behaviorDiagnosis: '',
            behavioralAdmissions: '',
            admissionsCount: ''
        }
    });
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(fetchDataUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token, ClientId: clientId }), 
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const data = await response.json();
    
                if (data && data.data) {
                    if (data.data.client && data.data.client.length > 0) {
                        const client = data.data.client[0];
                        const result = data.data.resultData && data.data.resultData.length > 0 ? data.data.resultData[0] : {}; 
                        
                        const ideationsArray = result.ideations ? result.ideations.split(',') : [];
                        const riskFactorsArray = result.risk_factors ? result.risk_factors.split(',') : [];
                        
                        setFormData({
                            firstName: client.fname || '',
                            lastName: client.lname || '',
                            dob: client.dob || '',
                            ssn: client.ssn || '',
                            appearance: result.appearance || '',
                            affect: result.affect || '',
                            diag_evl_id: result.diag_evl_id || 0,
                        });
    
                        setdignosticData({
                            ...result,
                            ideations: {
                                suicidal: ideationsArray[0] || '',
                                none_suicidal: ideationsArray[1] || '',
                                thoughts: ideationsArray[2] || '',
                                plan: ideationsArray[3] || '',
                                intent: ideationsArray[4] || '',
                                means: ideationsArray[5] || '',
                                homicidal: ideationsArray[6] || '',
                                none_homicidal: ideationsArray[7] || '',
                                homicidal_thoughts: ideationsArray[8] || '',
                                homicidal_plan: ideationsArray[9] || '',
                                homicidal_intent: ideationsArray[10] || '',
                                homicidal_means: ideationsArray[11] || '',
                            },
                            risks: {
                                nonCompliance: riskFactorsArray[0] || '',
                                domesticViolence: riskFactorsArray[1] || '',
                                childAbuse: riskFactorsArray[2] || '',
                                sexualAbuse: riskFactorsArray[3] || '',
                                suicidalIdeation: riskFactorsArray[4] || '',
                                eatingDisorder: riskFactorsArray[5] || '',
                                behaviorDiagnosis: riskFactorsArray[6] || '',
                                behavioralAdmissions: riskFactorsArray[7] || '',
                                admissionsCount: riskFactorsArray[8] || '',
                            },
                        });
                    } else {
                        setError('No client data found.');
                    }
                } else {
                    setError('No data found.');
                }
    
            } catch (err) {
                setError('Failed to fetch data.');
                console.error('Fetch error:', err);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [clientId, token, fetchDataUrl]); // Added token and fetchDataUrl to dependencies
    

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleValidation = () => {
        // Add validation logic if needed
        return true;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const keys = name.split('[').map(k => k.replace(']', ''));
    
        // Update formState
        setFormState(prevState => {
            let newState = { ...prevState };
            let current = newState;
            keys.forEach((key, index) => {
                if (index === keys.length - 1) {
                    current[key] = value;
                } else {
                    current[key] = current[key] || {};
                    current = current[key];
                }
            });
            console.log('Updated formState:', newState); // Check the updated state
            return newState;
        });
    
        // Update dignosticData
        setdignosticData(prevState => {
            let newState = { ...prevState };
            let current = newState;
            keys.forEach((key, index) => {
                if (index === keys.length - 1) {
                    current[key] = value;
                } else {
                    current[key] = current[key] || {};
                    current = current[key];
                }
            });
            console.log('Updated dignosticData:', newState); // Check the updated state
            return newState;
        });
    };
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Perform validation checks before submitting the form
        if (handleValidation()) {
          setLoading(true);
          const token = localStorage.getItem('token');
      
          // Prepare the data to be sent, ensuring all fields are included
          const dataToSend = {
            token : token || '',
            diag_evl_id: dignosticData.diag_evl_id || '',
            ClientId: clientId || 0, 
            axis_event: dignosticData.axis_event || '',
            problem_attemt: dignosticData.problem_attemt || '',
            problem_effective: dignosticData.problem_effective || '',
            injury_history: dignosticData.injury_history || '',
            ideations: {
                suicidal: dignosticData.ideations?.suicidal || '',
                none_suicidal: dignosticData.ideations?.none_suicidal || '',
                thoughts: dignosticData.ideations?.thoughts || '',
                plan: dignosticData.ideations?.plan || '',
                intent: dignosticData.ideations?.intent || '',
                means: dignosticData.ideations?.means || '',
                homicidal: dignosticData.ideations?.homicidal || '',
                none_homicidal: dignosticData.ideations?.none_homicidal || '',
                homicidal_thoughts: dignosticData.ideations?.homicidal_thoughts || '',
                homicidal_plan: dignosticData.ideations?.homicidal_plan || '',
                homicidal_intent: dignosticData.ideations?.homicidal_intent || '',
                homicidal_means: dignosticData.ideations?.homicidal_means || '',
            },
            weapons: dignosticData.weapons || '',
            safety: dignosticData.safety || '',
            risk1: dignosticData.risk1 || '',
            risk2: dignosticData.risk2 || '',
            plan_action: dignosticData.plan_action || '',
            appearance: dignosticData.appearance || '',
            affect: dignosticData.affect || '',
            affect_desc: dignosticData.affect_desc || '',
            orientation: dignosticData.orientation || '',
            thought_content: dignosticData.thought_content || '',
            thought_process: dignosticData.thought_process || '',
            speech: dignosticData.speech || '',
            motor: dignosticData.motor || '',
            intellect: dignosticData.intellect || '',
            insight: dignosticData.insight || '',
            judgment: dignosticData.judgment || '',
            impulse_control: dignosticData.impulse_control || '',
            persionality_disorder: dignosticData.persionality_disorder || '',
            care_physician: dignosticData.care_physician || '',
            telephone: dignosticData.telephone || '',
            m_condition: dignosticData.m_condition || '',
            c_medication: dignosticData.c_medication || '',
            r_medication: dignosticData.r_medication || '',
            surgeries: dignosticData.surgeries || '',
            outpatient_therapy: dignosticData.outpatient_therapy || '',
            psychiatric_history: dignosticData.psychiatric_history || '',
            householder: dignosticData.householder || '',
            child_residing: dignosticData.child_residing || '',
            family_member: dignosticData.family_member || '',
            family_along: dignosticData.family_along || '',
            signification_spouse: dignosticData.signification_spouse || '',
            edu: dignosticData.edu || '',
            military: dignosticData.military || '',
            goals: dignosticData.goals || '',
            job_desc: dignosticData.job_desc || '',
            pattern_emp: dignosticData.pattern_emp || '',
            income: dignosticData.income || '',
        
          
            abuse_prob: dignosticData.abuse_prob || '',
            cut_back: dignosticData.cut_back || '',
            substances: dignosticData.substances || '',
            cut_back_unsuccess: dignosticData.cut_back_unsuccess || '',
            withdrawal: dignosticData.withdrawal || '',
            blackouts: dignosticData.blackouts || '',
            poisoning: dignosticData.poisoning || '',
            other_behaviors: dignosticData.other_behaviors || '',
            complication: dignosticData.complication || '',
            family_addictions: dignosticData.family_addictions || '',
            step_12: dignosticData.step_12 || '',
            agencies: dignosticData.agencies || '',
            legal_issue: dignosticData.legal_issue || '',
            c_gaf: dignosticData.c_gaf || '',
            h_gaf: dignosticData.h_gaf || '',
            involvement: dignosticData.involvement || '',
            activities: dignosticData.activities || '',
            supportive: dignosticData.supportive || '',
            beliefs: dignosticData.beliefs || '',
            signification: dignosticData.signification || '',
            pregnancy: dignosticData.pregnancy || '',
            trauma: dignosticData.trauma || '',
            developmental: dignosticData.developmental || '',
            camt: dignosticData.camt || '',
            cfrequency: dignosticData.cfrequency || '',
            cfirst: dignosticData.cfirst || '',
            clast: dignosticData.clast || '',
            tamt: dignosticData.tamt || '',
            tfrequency: dignosticData.tfrequency || '',
            tfirst: dignosticData.tfirst || '',
            tlast: dignosticData.tlast || '',
            alamt: dignosticData.alamt || '',
            alfrequency: dignosticData.alfrequency || '',
            alfirst: dignosticData.alfirst || '',
            allast: dignosticData.allast || '',
            
            namt: dignosticData.namt || '',
            nfrequency: dignosticData.nfrequency || '',
            nfirst: dignosticData.nfirst || '',
            nlast: dignosticData.nlast || '',
            amamt: dignosticData.amamt || '',
            amfrequency: dignosticData.amfrequency || '',
            amfirst: dignosticData.amfirst || '',
            amlast: dignosticData.amlast || '',
            coamt: dignosticData.coamt || '',
            cofrequency: dignosticData.cofrequency || '',
            cofirst: dignosticData.cofirst || '',
            colast: dignosticData.colast || '',
            hamt: dignosticData.hamt || '',
            hfrequency: dignosticData.hfrequency || '',
            hfirst: dignosticData.hfirst || '',
            hlast: dignosticData.hlast || '',
            oamt: dignosticData.oamt || '',
            ofrequency: dignosticData.ofrequency || '',
            ofirst: dignosticData.ofirst || '',
            olast: dignosticData.olast || '',
           
            risks: {
                nonCompliance: dignosticData.risks?.nonCompliance || '',
                domesticViolence: dignosticData.risks?.domesticViolence || '',
                childAbuse: dignosticData.risks?.childAbuse || '',
                sexualAbuse: dignosticData.risks?.sexualAbuse || '',
                suicidalIdeation: dignosticData.risks?.suicidalIdeation || '',
                eatingDisorder: dignosticData.risks?.eatingDisorder || '',
                behaviorDiagnosis: dignosticData.risks?.behaviorDiagnosis || '',
                behavioralAdmissions: dignosticData.risks?.behavioralAdmissions || '',
                admissionsCount: dignosticData.risks?.admissionsCount || '',
             
            },
            
        };
        
        
        
                
          console.log('Data to be sent:', dataToSend); // Log data to check
      
          try {
            const response = await fetch(addDiagnosticUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(dataToSend),
            });
      
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
      
            const data = await response.json();
            console.log('Response data:', data);
      
            if (data.status === 'success') {
              alert('Client updated successfully!');
              navigate(`${baseUrl}/clients`);
            } else {
              alert('Failed to update client.');
            }
          } catch (err) {
            setError('Failed to submit the form.');
            console.error(err);
          } finally {
            setLoading(false);
          }
        }
      };
      
    
    

    return (
        <>
            <div className="">
                <div className='flex items-center justify-between bg-darklightgrey'>
                    <div className='container mx-auto flex justify-between py-[20px] px-[40px]'>
                        <div className="">
                            <h3 className='text-primary text-[20px] md:text-[30px] font-bold leading-none'>Diagnostic Evaluation</h3>
                            <h4 className='text-base md:text-xl font-bold'>{formData.lastName}, {formData.firstName}</h4>
                            <ul className='font-bold text-[#3b3c51] text-sm space-x-3 flex'>
                                <li>
                                    <span className='text-primary mr-2'>DOB:</span>
                                    {formData.dob}
                                </li>
                                <li>
                                    <span className='text-primary mr-2'>SS#:{formData.ssn}</span>
                                </li>
                            </ul>
                        </div>
                        <div className='flex items-center gap-5'>
                            <button
                                className='bg-primary text-white w-[150px] md:w-[300px] md:flex hidden items-center justify-center py-[15px] rounded-[60px] font-bold text-[23px]'
                                onClick={handleSubmit} disabled={loading}
                            >
                                Save
                            </button>
                            <Link to={`${baseUrl}/welcome/dashboard`} className='text-[20px] font-bold text-primary'>
                                <Image src="common/cross-sign.png" alt="logo" width="35" height="30" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='container mx-auto md:mx-0 w-full p-[20px]'>
                    <div className="lg:p-6">
                        <form className="" onSubmit={handleSubmit} method="POST">
                            {/* Axis 1 */}
                            <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2'>
                                Axis 1: Clinical Disorders, Precipitating Problems & Diagnosis
                            </h4>
                            <input type="hidden" name="diag_evl_id" value={dignosticData.diag_evl_id || ''}/>
                            <div className="mt-3">
                                <label htmlFor="problem_effective" className="block text-primary font-bold text-[20px]">
                                    Primary Reason for Visit & Precipitating Event:
                                </label>
                                <textarea
                                    name="axis_event"
                                    id="axis_event"
                                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 resize-none h-[145px] md:h-[163px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    value={dignosticData.axis_event || ''}
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Prior Efforts */}
                            <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2'>
                                Prior Efforts to Address Problem
                            </h4>
                            <div className="mt-3">
                                <label htmlFor="problem_attemt" className="block text-primary font-bold text-[20px]">
                                    Other therapists or personal attempts:
                                </label>
                                <textarea
                                    name="problem_attemt"
                                    id="problem_attemt"
                                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 resize-none h-[145px] md:h-[163px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    value={dignosticData.problem_attemt || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mt-3">
                                <label htmlFor="problem_effective" className="block text-primary font-bold text-[20px]">
                                    Were these efforts effective?:
                                </label>
                                <input
                                    type="text"
                                    id="problem_effective"
                                    name="problem_effective"
                                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    value={dignosticData.problem_effective || ''}
                                    onChange={handleChange}
                                />
                            </div>
                            {/*  */}
                            <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2'>
                                Risk Assessment
                            </h4>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">Self-injury history:</label>
                                <input
                                    type="text"
                                    id="injury_history"
                                    name="injury_history"
                                    value={dignosticData.injury_history || ''}
                                    onChange={handleChange}
                                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            {/*  */}
                            <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2'>
                                Ideations
                            </h4>
                            <div className="grid gap-3 grid-cols-1 md:grid-cols-2 lg:w-[80%]">
                                <div className="">
                                    <label htmlFor="suicidal" className="block text-primary font-bold text-[20px]">Suicidal</label>
                                    <input
                                        type="text"
                                        id="suicidal"
                                        name="ideations[suicidal]"
                                        onChange={handleChange}
                                        value={dignosticData.ideations.suicidal || ''}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="none_suicidal" className="block text-primary font-bold text-[20px]">None:</label>
                                    <input
                                        type="text"
                                        id="none_suicidal"
                                        name="ideations[none_suicidal]"
                                        value={dignosticData.ideations.none_suicidal || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="thoughts" className="block text-primary font-bold text-[20px]">Thoughts</label>
                                    <input
                                        type="text"
                                        id="thoughts"
                                        name="ideations[thoughts]"
                                        value={dignosticData.ideations.thoughts || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="plan" className="block text-primary font-bold text-[20px]">Plan:</label>
                                    <input
                                        type="text"
                                        id="plan"
                                        name="ideations[plan]"
                                        value={dignosticData.ideations.plan || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="intent" className="block text-primary font-bold text-[20px]">Intent</label>
                                    <input
                                        type="text"
                                        id="intent"
                                        name="ideations[intent]"
                                        value={dignosticData.ideations.intent || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="means" className="block text-primary font-bold text-[20px]">Means:</label>
                                    <input
                                        type="text"
                                        id="means"
                                        name="ideations[means]"
                                        value={dignosticData.ideations.means || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="homicidal" className="block text-primary font-bold text-[20px]">Homicidal</label>
                                    <input
                                        type="text"
                                        id="homicidal"
                                        name="ideations[homicidal]"
                                        value={dignosticData.ideations.homicidal || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="none_homicidal" className="block text-primary font-bold text-[20px]">None:</label>
                                    <input
                                        type="text"
                                        id="none_homicidal"
                                        name="ideations[none_homicidal]"
                                        value={dignosticData.ideations.none_homicidal || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="homicidal_thoughts" className="block text-primary font-bold text-[20px]">Thoughts</label>
                                    <input
                                        type="text"
                                        id="homicidal_thoughts"
                                        name="ideations[homicidal_thoughts]"
                                        value={dignosticData.ideations.homicidal_thoughts || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="homicidal_plan" className="block text-primary font-bold text-[20px]">Plan:</label>
                                    <input
                                        type="text"
                                        id="homicidal_plan"
                                        name="ideations[homicidal_plan]"
                                        value={dignosticData.ideations.homicidal_plan || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="homicidal_intent" className="block text-primary font-bold text-[20px]">Intent</label>
                                    <input
                                        type="text"
                                        id="homicidal_intent"
                                        name="ideations[homicidal_intent]"
                                        value={dignosticData.ideations.homicidal_intent || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="homicidal_means" className="block text-primary font-bold text-[20px]">Means:</label>
                                    <input
                                        type="text"
                                        id="homicidal_means"
                                        name="ideations[homicidal_means]"
                                        value={dignosticData.ideations.homicidal_means || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">Does client have access to weapons?</label>
                                    <input
                                        type="text"
                                        id="weapons"
                                        name="weapons"
                                        value={dignosticData.weapons || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">is client able to contract for safety?:</label>
                                    <input
                                        type="text"
                                        id="safety"
                                        name="safety"
                                        value={dignosticData.safety || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="md:col-span-2">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">Involved family/other support system members in suicide management plan</label>
                                    <input
                                        type="text"
                                        id="risk1"
                                        name="risk1"
                                        value={dignosticData.risk1 || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full md:w-3/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="md:col-span-2">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">Developed a plan of action ie. Hospital emergency room: </label>
                                    <input
                                        type="text"
                                        id="risk2"
                                        name="risk2"
                                        value={dignosticData.risk2 || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full md:w-3/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">Plan of action: </label>
                                <textarea name="plan_action" id="plan_action" className="mt-1 block w-full shadow-sm focus:border-indigo-300  resize-none h-[145px] md:h-[163px]  rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={dignosticData.plan_action || ''} onChange={handleChange}></textarea>
                            </div>
                            {/*  */}
                            <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2 mt-2'>
                                Mental Status: (circle appropriate items):
                                
                            </h4>
                            <div className="">
                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="appearance"><span className="block text-primary font-bold text-[20px]">Appearance :</span></FormLabel>
                                    <RadioGroup
                                       row
                                       aria-labelledby="appearance"
                                       value={formState.appearance}
                                       name="appearance"
                                       onChange={handleChange}
                                    >
                                        <FormControlLabel value="appropriate" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': { color: '#7A65AC' },
                                        }} />} label="Appropriate :" labelPlacement="start" />
                                        <FormControlLabel value="inappropriate" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': { color: '#7A65AC' },
                                        }} />} label="Inappropriate :" labelPlacement="start" />
                                        <FormControlLabel value="disheveled_unclean" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': { color: '#7A65AC' },
                                        }} />} label="Disheveled Unclean :" labelPlacement="start" />
                                        <FormControlLabel value="bizarre" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': { color: '#7A65AC' },
                                        }} />} label="Bizarre :" labelPlacement="start" />
                                    </RadioGroup>
                                </div>
                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="affect"><span className="block text-primary font-bold text-[20px]">Affect :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="affect"
                                        value={formState.affect}
                                        name="affect"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="appropriate" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Appropriate :" labelPlacement="start" />
                                        <FormControlLabel value="inappropriate" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Inappropriate :" labelPlacement="start" />

                                    </RadioGroup>
                                </div>
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">Description </label>
                                <textarea name="affect_desc" id="affect_desc" className="mt-1 block w-full shadow-sm focus:border-indigo-300  resize-none h-[145px] md:h-[163px]  rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={dignosticData.affect_desc || ''}></textarea>
                            </div>
                            <div className="">
                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="orientation"><span className="block text-primary font-bold text-[20px]">Orientation :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="orientation"
                                        value={formState.orientation}
                                        name="orientation"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="oriented" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Oriented :" labelPlacement="start" />
                                        <FormControlLabel value="disoriented" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Disoriented :" labelPlacement="start" />
                                    </RadioGroup>
                                </div>
                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="thought_content"><span className="block text-primary font-bold text-[20px]">Thought Content :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="thought_content"
                                        value={formState.thought_content}
                                        name="thought_content"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="appropriate" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Appropriate :" labelPlacement="start" />
                                        <FormControlLabel value="inappropriate" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Inappropriate :" labelPlacement="start" />

                                    </RadioGroup>
                                </div>
                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="thought_process"><span className="block text-primary font-bold text-[20px]">Thought Process :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="thought_process"
                                        value={formState.thought_process}
                                        name="thought_process"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="logical" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Logical :" labelPlacement="start" />
                                        <FormControlLabel value="tangential" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Tangential :" labelPlacement="start" />
                                        <FormControlLabel value="illogical" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Illogical :" labelPlacement="start" />

                                    </RadioGroup>
                                </div>
                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="speech"><span className="block text-primary font-bold text-[20px]">Speech :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="speech"
                                        value={formState.speech}
                                        name="speech"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="normal" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Normal :" labelPlacement="start" />
                                        <FormControlLabel value="slurred" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Slurred :" labelPlacement="start" />
                                        <FormControlLabel value="slow" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Slow :" labelPlacement="start" />
                                        <FormControlLabel value="pressured" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Pressured :" labelPlacement="start" />
                                        <FormControlLabel value="loud" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Loud :" labelPlacement="start" />

                                    </RadioGroup>
                                </div>
                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="motor"><span className="block text-primary font-bold text-[20px]">Motor :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="motor"
                                        value={formState.motor}
                                        name="motor"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="normal" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Normal :" labelPlacement="start" />
                                        <FormControlLabel value="excessive" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Excessive :" labelPlacement="start" />
                                        <FormControlLabel value="slow" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Slow :" labelPlacement="start" />
                                    </RadioGroup>
                                </div>
                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="intellect"><span className="block text-primary font-bold text-[20px]">Intellect :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="intellect"
                                        value={formState.intellect}
                                        name="intellect"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="average" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Average :" labelPlacement="start" />
                                        <FormControlLabel value="above_average" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Above Average :" labelPlacement="start" />
                                        <FormControlLabel value="below_average" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Below Average :" labelPlacement="start" />
                                    </RadioGroup>
                                </div>
                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="insight"><span className="block text-primary font-bold text-[20px]">Insight :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="insight"
                                        value={formState.insight}
                                        name="insight"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="present" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Present :" labelPlacement="start" />
                                        <FormControlLabel value="partially_present" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Partially Present :" labelPlacement="start" />
                                        <FormControlLabel value="absent" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Absent :" labelPlacement="start" />

                                    </RadioGroup>
                                </div>

                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="judgment"><span className="block text-primary font-bold text-[20px]">Judgment :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="judgment"
                                        value={formState.judgment}
                                        name="judgment"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="normal" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Normal :" labelPlacement="start" />
                                        <FormControlLabel value="impaired" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Impaired :" labelPlacement="start" />
                                    </RadioGroup>
                                </div>

                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="impulse_control"><span className="block text-primary font-bold text-[20px]">Impulse Control :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="impulse_control"
                                        value={formState.impulse_control}
                                        name="impulse_control"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="normal" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Normal :" labelPlacement="start" />
                                        <FormControlLabel value="impaired" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Impaired :" labelPlacement="start" />
                                    </RadioGroup>
                                </div>
                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="memory"><span className="block text-primary font-bold text-[20px]">Memory :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="memory"
                                        value={formState.memory}
                                        name="memory"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="normal" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Normal :" labelPlacement="start" />
                                        <FormControlLabel value="impaired" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Impaired :" labelPlacement="start" />
                                        <FormControlLabel value="Recent" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Recent :" labelPlacement="start" />
                                        <FormControlLabel value="remote" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Remote :" labelPlacement="start" />
                                        <FormControlLabel value="immediate" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Immediate :" labelPlacement="start" />
                                    </RadioGroup>
                                </div>

                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="concentration"><span className="block text-primary font-bold text-[20px]">Concentration :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="concentration"
                                        value={formState.concentration}
                                        name="concentration"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="normal" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Normal :" labelPlacement="start" />
                                        <FormControlLabel value="impaired" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Impaired :" labelPlacement="start" />
                                    </RadioGroup>
                                </div>
                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="attention"><span className="block text-primary font-bold text-[20px]">Attention :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="attention"
                                        value={formState.attention}
                                        name="attention"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="normal" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Normal :" labelPlacement="start" />
                                        <FormControlLabel value="impaired" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Impaired :" labelPlacement="start" />
                                    </RadioGroup>
                                </div>
                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="behavior"><span className="block text-primary font-bold text-[20px]">Behavior :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="behavior"
                                        value={formState.behavior}
                                        name="behavior"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="appropriate" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Appropriate :" labelPlacement="start" />
                                        <FormControlLabel value="inappropriate" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Inappropriate (anxious, guarded, hostile, uncooperative, drowsy) :" labelPlacement="start" />
                                    </RadioGroup>
                                </div>

                                <div className="flex md:items-center flex-col md:flex-row">

                                    <FormLabel id="thought_disorder"><span className="block text-primary font-bold text-[20px]">Thought Disorder :</span></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="thought_disorder"
                                        value={formState.thought_disorder}
                                        name="thought_disorder"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="no_problem" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="No Problem :" labelPlacement="start" />
                                        <FormControlLabel value="paranoid" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Paranoid :" labelPlacement="start" />
                                        <FormControlLabel value="delusions" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Delusions :" labelPlacement="start" />
                                        <FormControlLabel value="grandiosity" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Grandiosity :" labelPlacement="start" />
                                        <FormControlLabel value="confusion" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Confusion :" labelPlacement="start" />
                                        <FormControlLabel value="confusion" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Confusion :" labelPlacement="start" />
                                        <FormControlLabel value="perseveration" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Perseveration :" labelPlacement="start" />
                                        <FormControlLabel value="tangential" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Tangential :" labelPlacement="start" />
                                        <FormControlLabel value="loose_associations" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Loose Associations :" labelPlacement="start" />
                                        <FormControlLabel value="thought" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Thought :" labelPlacement="start" />
                                        <FormControlLabel value="blocking_flight_of_ideas" control={<Radio sx={{
                                            color: '#7A65AC',
                                            '&.Mui-checked': {
                                                color: '#7A65AC',
                                            },
                                        }} />} label="Blocking Flight of Ideas :" labelPlacement="start" />

                                    </RadioGroup>
                                </div>
                            </div>
                            {/*  */}
                            <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2 mt-2'>
                                Axis II: Personality Disorder (Usually Deferred)
                            </h4>
                                <div className="mt-3">
                                    <textarea name="persionality_disorder" id="persionality_disorder" className="mt-1 block w-full shadow-sm focus:border-indigo-300  resize-none h-[145px] md:h-[163px]  rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={dignosticData.persionality_disorder || ''} onChange={handleChange}></textarea>
                                </div>
                                {/*  */}
                                <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2 mt-2'>
                                    Axis III: General Medical & Psychiatric History
                                </h4>
                                <div className="grid gap-3 grid-cols-1 md:grid-cols-2 lg:w-[80%]">
                                    <div className="">
                                        <label htmlFor="" className="block text-primary font-bold text-[20px]">Primary Care Physician:</label>
                                        <input
                                            type="text"
                                            id="care_physician"
                                            name="care_physician"
                                            value={dignosticData.care_physician || ''}
                                            onChange={handleChange}
                                            className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                        />
                                    </div>
                                    <div className="">
                                        <label htmlFor="" className="block text-primary font-bold text-[20px]">Telephone</label>
                                        <input
                                            type="text"
                                            id="telephone"
                                            name="telephone"
                                            value={dignosticData.telephone || ''}
                                            onChange={handleChange}
                                            className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">Relevant Medical Conditions: </label>
                                    <textarea name="m_condition" id="m_condition" className="mt-1 block w-full shadow-sm focus:border-indigo-300  resize-none h-[145px] md:h-[163px]  rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={dignosticData.m_condition || ''} onChange={handleChange}> </textarea>
                                </div>
                                <div className="mt-3">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">Current Medications: </label>
                                    <textarea name="c_medication" id="c_medication" className="mt-1 block w-full shadow-sm focus:border-indigo-300  resize-none h-[145px] md:h-[163px]  rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={dignosticData.c_medication} onChange={handleChange}></textarea>
                                </div>
                                <div className="grid gap-3 grid-cols-1 md:grid-cols-2 lg:w-[80%] mt-3">
                                    <div className="">
                                        <label htmlFor="" className="block text-primary font-bold text-[20px]">Adverse Reactions to Medications:</label>
                                        <input
                                            type="text"
                                            id="r_medication"
                                            name="r_medication"
                                            value={dignosticData.r_medication}
                                            onChange={handleChange}
                                            className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                        />
                                    </div>
                                    <div className="">
                                        <label htmlFor="" className="block text-primary font-bold text-[20px]">Hospitalizations/Surgeries:</label>
                                        <input
                                            type="text"
                                            id="surgeries"
                                            name="surgeries"
                                            onChange={handleChange}
                                            value={dignosticData.surgeries}
                                            className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                        />
                                    </div>
                                    <div className="">
                                        <label htmlFor="" className="block text-primary font-bold text-[20px]">Prior Outpatient Therapy:</label>
                                        <input
                                            type="text"
                                            id="outpatient_therapy"
                                            name="outpatient_therapy"
                                            onChange={handleChange}
                                            value={dignosticData.outpatient_therapy}
                                            className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                        />
                                    </div>
                                    <div className="">
                                        <label htmlFor="" className="block text-primary font-bold text-[20px]">Past Psychiatric History:</label>
                                        <input
                                            type="text"
                                            id="psychiarric_hostory"
                                            name="psychiarric_hostory"
                                            onChange={handleChange}
                                            value={dignosticData.psychiarric_hostory}
                                            className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                        />
                                    </div>
                                </div>
                                {/*  */}
                                <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2 mt-2'>
                                    Axis IV: Psychosocial & Environmental - Nature of Stressors ____Mild ____Moderate _____Severe Family & support systems
                                </h4>
                                <div className="mt-3">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">Who Lives in current household: (Ages & Relationship): </label>
                                    <textarea name="householder" id="householder" className="mt-1 block w-full shadow-sm focus:border-indigo-300  resize-none h-[145px] md:h-[163px]  rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={dignosticData.householder} onChange={handleChange}></textarea>
                                </div>
                                <div className="mt-3">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">Children residing elsewhere: </label>

                                    <input
                                        type="text"
                                        id="child_residing"
                                        name="child_residing"
                                        value={dignosticData.child_residing}
                                        onChange={handleChange}
                                        className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="mt-3">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">Other Significant Family Members (Grandparents, ex-spouse etc.): </label>
                                    <textarea name="family_member" id="family_member" className="mt-1 block w-full shadow-sm focus:border-indigo-300  resize-none h-[145px] md:h-[163px]  rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={dignosticData.family_member} onChange={handleChange}> </textarea>
                                </div>
                                <div className="mt-3">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">How does the family generally get along?:</label>
                                    <textarea name="family_along" id="family_along" className="mt-1 block w-full shadow-sm focus:border-indigo-300  resize-none h-[145px] md:h-[163px]  rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={dignosticData.family_along} onChange={handleChange}></textarea>
                                </div>
                                <div className="mt-3">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                        Relationship with spouse of significant other (if applicable):</label>
                                    <input
                                        type="text"
                                        id="signification_spouse"
                                        name="signification_spouse"
                                        value={dignosticData.signification_spouse}
                                        onChange={handleChange}
                                        className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                {/*  */}
                                <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2 mt-2'>
                                    Education and/or Job History
                                </h4>
                                <div className="mt-3">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                        Highest Grade/ Education Level Completed:</label>
                                    <input
                                        type="text"
                                        id="edu"
                                        name="edu"
                                        value={dignosticData.edu}
                                        onChange={handleChange}
                                        className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                {/*  */}
                                <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2 mt-2'>
                                    Education and/or Job History
                                </h4>

                                <div className="grid gap-3 grid-cols-1 md:grid-cols-2 lg:w-[80%] mt-3">
                                    <div className="">
                                        <label htmlFor="" className="block text-primary font-bold text-[20px]">Any Military (indicate branch):</label>
                                        <input
                                            type="text"
                                            id="military"
                                            name="military"
                                            value={dignosticData.military}
                                            onChange={handleChange}
                                            className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                        />
                                    </div>
                                    <div className="">
                                        <label htmlFor="" className="block text-primary font-bold text-[20px]">Any future education goals:</label>
                                        <input
                                            type="text"
                                            id="goals"
                                            name="goals"
                                            value={dignosticData.goals}
                                            onChange={handleChange}
                                            className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">Employment and job description:</label>
                                    <textarea name="job_desc" id="job_desc" className="mt-1 block w-full shadow-sm focus:border-indigo-300  resize-none h-[145px] md:h-[163px]  rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={dignosticData.job_desc}
                                        onChange={handleChange}></textarea>
                                </div>
                                <div className="grid gap-3 grid-cols-1 md:grid-cols-2 lg:w-[80%] mt-3">
                                    <div className="">
                                        <label htmlFor="" className="block text-primary font-bold text-[20px]">Pattern of Employment (consistent/sporadic):</label>
                                        <input
                                            type="text"
                                            id="pattern_emp"
                                            name="pattern_emp"
                                            value={dignosticData.pattern_emp}
                                            onChange={handleChange}
                                            className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                        />
                                    </div>
                                    <div className="">
                                        <label htmlFor="" className="block text-primary font-bold text-[20px]">If not employed, sources of income:</label>
                                        <input
                                            type="text"
                                            id="income"
                                            name="income"
                                            value={dignosticData.income}
                                            onChange={handleChange}
                                            className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                        />
                                    </div>
                            </div>
                            {/*  */}
                            <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2 mt-2'>
                                Social Support Systems, Relationships, Coping Mechanisms & Situational Stressors
                            </h4>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">Involvement in Groups (Community, Church, Social, Athletic):</label>
                                <textarea name="involvement" id="involvement" className="mt-1 block w-full shadow-sm focus:border-indigo-300  resize-none h-[145px] md:h-[163px]  rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={dignosticData.involvement}
                                    onChange={handleChange}></textarea>
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">Activities you enjoy (exercise, hobbies, meditation, sports, leisure):</label>
                                <textarea name="activities" id="activities" className="mt-1 block w-full shadow-sm focus:border-indigo-300  resize-none h-[145px] md:h-[163px]  rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={dignosticData.activities}
                                    onChange={handleChange}></textarea>
                            </div>
                            <div className="grid gap-3 grid-cols-1 md:grid-cols-2 lg:w-[80%] mt-3">
                                <div className="">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">Who is most supportive of you:</label>
                                    <input
                                        type="text"
                                        id="supportive"
                                        name="supportive"
                                        value={dignosticData.supportive}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">Spiritual Beliefs:</label>
                                    <input
                                        type="text"
                                        id="beliefs"
                                        name="beliefs"
                                        value={dignosticData.beliefs}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">Any significant life changes/problems in the last 6 months (marriage, death, birth, job change, divorce):</label>
                                <textarea name="signification" id="signification" className="mt-1 block w-full shadow-sm focus:border-indigo-300  resize-none h-[145px] md:h-[163px]  rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={dignosticData.signification}
                                    onChange={handleChange}></textarea>
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Difficulties or use of medications during mother's pregnancy:
                                </label>
                                <input
                                    type="text"
                                    id="pregnancy"
                                    name="pregnancy"
                                    value={dignosticData.pregnancy}
                                    onChange={handleChange}
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Major childhood Illness or head trauma:
                                </label>
                                <input
                                    type="text"
                                    id="trauma"
                                    name="trauma"
                                    value={dignosticData.trauma}
                                    onChange={handleChange}
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Developmental History: (milestones met)
                                </label>
                                <input
                                    type="text"
                                    id="developmental"
                                    name="developmental"
                                    value={dignosticData.developmental}
                                    onChange={handleChange}
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            {/*  */}
                            <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2 mt-2'>
                                Risk Factors
                            </h4>
                            <div className="grid gap-3 grid-cols-1 md:grid-cols-2 lg:w-[80%] mt-3">
                                <div className="">
                                    <label htmlFor="nonCompliance" className="block text-primary font-bold text-[20px]">Non-compliance with treatment:</label>
                                    <input
                                        type="text"
                                        id="nonCompliance"
                                        name="risks[nonCompliance]"
                                        value={dignosticData.risks?.nonCompliance || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="domesticViolence" className="block text-primary font-bold text-[20px]">Domestic Violence:</label>
                                    <input
                                        type="text"
                                        id="domesticViolence"
                                        name="risks[domesticViolence]"
                                        value={dignosticData.risks?.domesticViolence || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="childAbuse" className="block text-primary font-bold text-[20px]">Child Abuse:</label>
                                    <input
                                        type="text"
                                        id="childAbuse"
                                        name="risks[childAbuse]"
                                        value={dignosticData.risks?.childAbuse || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="sexualAbuse" className="block text-primary font-bold text-[20px]">Sexual Abuse:</label>
                                    <input
                                        type="text"
                                        id="sexualAbuse"
                                        name="risks[sexualAbuse]"
                                        value={dignosticData.risks?.sexualAbuse || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="suicidalIdeation" className="block text-primary font-bold text-[20px]">Suicidal/Homicidal Ideation:</label>
                                    <input
                                        type="text"
                                        id="suicidalIdeation"
                                        name="risks[suicidalIdeation]"
                                        value={dignosticData.risks?.suicidalIdeation || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="eatingDisorder" className="block text-primary font-bold text-[20px]">Eating Disorder - describe:</label>
                                    <input
                                        type="text"
                                        id="eatingDisorder"
                                        name="risks[eatingDisorder]"
                                        value={dignosticData.risks?.eatingDisorder || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="behaviorDiagnosis" className="block text-primary font-bold text-[20px]">History of multiple behavior diagnosis:</label>
                                    <input
                                        type="text"
                                        id="behaviorDiagnosis"
                                        name="risks[behaviorDiagnosis]"
                                        value={dignosticData.risks?.behaviorDiagnosis || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="behavioralAdmissions" className="block text-primary font-bold text-[20px]">Prior behavioral health inpatient admissions:</label>
                                    <input
                                        type="text"
                                        id="behavioralAdmissions"
                                        name="risks[behavioralAdmissions]"
                                        value={dignosticData.risks?.behavioralAdmissions || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="admissionsCount" className="block text-primary font-bold text-[20px]">How many:</label>
                                    <input
                                        type="text"
                                        id="admissionsCount"
                                        name="risks[admissionsCount]"
                                        value={dignosticData.risks?.admissionsCount || ''}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>

                            </div>
                            <div className="overflow-x-auto mt-3 w-full">
                                <table className='md:min-w-full text-left'>
                                    <thead>
                                        <tr className='border-t border-[#ddd]'>
                                            <td className='w-52 py-2'>
                                                Substance
                                            </td>
                                            <td className='w-52'>
                                                Amount
                                            </td>
                                            <td className='w-52'>
                                                Frequency
                                            </td>
                                            <td className='w-52'>
                                                First Use
                                            </td>
                                            <td className='w-52'>
                                                Last Use
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='border-t border-[#ddd] '>
                                            <td className='py-2'>
                                                Caffeine
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='camt' id='camt' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.camt} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='cfrequency' id='cfrequency' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.cfrequency} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='cfirst' id='cfirst' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.cfirst} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='clast' id='clast' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.clast} onChange={handleChange} />
                                            </td>
                                        </tr>
                                        <tr className='border-t border-[#ddd] '>
                                            <td className='py-2'>
                                                Tobacco
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='tamt' id='tamt' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.tamt} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='tfrequency' id='tfrequency' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.tfrequency} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='tfirst' id='tfirst' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.tfirst} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='tlast' id='tlast' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.tlast} onChange={handleChange} />
                                            </td>
                                        </tr>
                                        <tr className='border-t border-[#ddd] '>
                                            <td className='py-2'>
                                                Alcohol
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='alamt' id='alamt' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.alamt} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='alfrequency' id='alfrequency' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.alfrequency} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='alfirst' id='alfirst' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.alfirst} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='allast' id='allast' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.allast} onChange={handleChange} />
                                            </td>
                                        </tr>
                                        <tr className='border-t border-[#ddd] '>
                                            <td className='py-2'>
                                                Opiates/ Narcotics
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='namt' id='namt' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.namt} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='nfrequency' id='nfrequency' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.nfrequency} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='nfirst' id='nfirst' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.nfirst} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='nlast' id='nlast' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.nlast} onChange={handleChange} />
                                            </td>
                                        </tr>
                                        <tr className='border-t border-[#ddd] '>
                                            <td className='py-2'>
                                                Amphetamines
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='amamt' id='amamt' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.amamt} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='amfrequency' id='amfrequency' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.amfrequency} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='amfirst' id='amfirst' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.amfirst} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='amlast' id='amlast' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.amlast} onChange={handleChange} />
                                            </td>
                                        </tr>
                                        <tr className='border-t border-[#ddd] '>
                                            <td className='py-2'>
                                                Cocaine
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='coamt' id='coamt' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.coamt} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='cofrequency' id='cofrequency' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.cofrequency} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='cofirst' id='cofirst' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.cofirst} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='colast' id='colast' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.colast} onChange={handleChange} />
                                            </td>
                                        </tr>
                                        <tr className='border-t border-[#ddd] '>
                                            <td className='py-2'>
                                                Hallucinogens
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='hamt' id='hamt' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.hamt} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='hfrequency' id='hfrequency' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.hfrequency} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='hfirst' id='hfirst' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.hfirst} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='hlast' id='hlast' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.hlast} onChange={handleChange} />
                                            </td>
                                        </tr>
                                        <tr className='border-t border-[#ddd] '>
                                            <td className='py-2'>
                                                Other
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='oamt' id='oamt' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.oamt} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='ofrequency' id='ofrequency' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.ofrequency} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='ofirst' id='ofirst' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.ofirst} onChange={handleChange} />
                                            </td>
                                            <td className='py-2'>
                                                <input type="text" name='olast' id='olast' className=' block w-[210px] shadow-sm focus:border-indigo-300 h-8 rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary' value={dignosticData.olast} onChange={handleChange} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Do you feel that you have a substance abuse problem?:
                                </label>
                                <input
                                    type="text"
                                    id="abuse_prob"
                                    name="abuse_prob"
                                    value={dignosticData.abuse_prob}
                                    onChange={handleChange}
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Does anyone try to get them to stop or cut back?:
                                </label>
                                <input
                                    type="text"
                                    id="cut_back"
                                    name="cut_back"
                                    value={dignosticData.cut_back}
                                    onChange={handleChange}

                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Does the client feel guilty about their use of any of these substances?:
                                </label>
                                <input
                                    type="text"
                                    id="substances"
                                    name="substances"
                                    value={dignosticData.substances}
                                    onChange={handleChange}
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Has the client ever tried to cut back and been unsuccessful?:
                                </label>
                                <input
                                    type="text"
                                    id="cut_back_unsuccess"
                                    value={dignosticData.cut_back_unsuccess}
                                    onChange={handleChange}
                                    name="cut_back_unsuccess"
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Tolerance or Withdrawal:
                                </label>
                                <input
                                    type="text"
                                    id="withdrawal"

                                    name="withdrawal"
                                    value={dignosticData.withdrawal}
                                    onChange={handleChange}
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Episodes of vomiting and/or blackouts?:
                                </label>
                                <input
                                    type="text"
                                    id="blackouts"
                                    value={dignosticData.blackouts}
                                    onChange={handleChange}
                                    name="blackouts"
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Overdoses, including alcohol poisoning?:
                                </label>
                                <input
                                    type="text"
                                    id="poisoning"
                                    value={dignosticData.poisoning}
                                    onChange={handleChange}
                                    name="poisoning"
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">Other addictive behaviors (gambling, eating, thrill seeking, sexual, etc):</label>
                                <textarea name="other_behaviors" id="other_behaviors" className="mt-1 block w-full shadow-sm focus:border-indigo-300  resize-none h-[145px] md:h-[163px]  rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={dignosticData.other_behaviors}
                                    onChange={handleChange}> </textarea>
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Any past or current legal complications from addictions:
                                </label>
                                <input
                                    type="text"
                                    id="complication"
                                    name="complication"
                                    value={dignosticData.complication}
                                    onChange={handleChange}
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>

                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Family history of addictions:
                                </label>
                                <input
                                    type="text"
                                    id="family_addictions"
                                    name="family_addictions"
                                    value={dignosticData.family_addictions}
                                    onChange={handleChange}
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    12 Step attendance or other recovery supports:
                                </label>
                                <input
                                    type="text"
                                    id="step_12"
                                    name="step_12"
                                    value={dignosticData.step_12}
                                    onChange={handleChange}
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            {/*  */}
                            <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2 mt-2'>
                                Agency Involvement
                            </h4>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Any involvement with any social service agencies?:
                                </label>
                                <input
                                    type="text"
                                    id="agencies"
                                    name="agencies"
                                    value={dignosticData.agencies}
                                    onChange={handleChange}
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            <div className="mt-3">
                                <label htmlFor="" className="block text-primary font-bold text-[20px]">
                                    Any other legal issues?:
                                </label>
                                <input
                                    type="text"
                                    id="legal_issue"
                                    name="legal_issue"
                                    value={dignosticData.legal_issue}
                                    onChange={handleChange}
                                    className="mt-1 block w-full lg:w-2/5 shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                />
                            </div>
                            {/*  */}
                            <h4 className='text-[#3b3c5] text-lg md:text-2xl  font-bold py-2 mt-2'>
                                Axis V:
                            </h4>
                            <div className="grid gap-3 grid-cols-1 md:grid-cols-2 lg:w-[80%] mt-3">
                                <div className="">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">Current GAF:</label>
                                    <input
                                        type="text"
                                        id="c_gaf"
                                        name="c_gaf"
                                        value={dignosticData.c_gaf}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="">
                                    <label htmlFor="" className="block text-primary font-bold text-[20px]">Highest GAF:</label>
                                    <input
                                        type="text"
                                        id="h_gaf"
                                        name="h_gaf"
                                        value={dignosticData.h_gaf}
                                        onChange={handleChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                            </div>
                            <div className="mt-10 lg:mt-20">
                                <button className='bg-primary text-white w-[150px] md:w-[300px] md:flex hidden items-center justify-center py-[15px] rounded-[60px] font-bold text-[23px]'>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Diagnostic