import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Image from '../../components/image/Image';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Link } from 'react-router-dom';
import data from '../../data/dashboardData.json'; 
const baseUrl = "/purple-cloud";
function UpdateTherapist() {
    const { id } = useParams(); 
    const [formData, setFormData] = useState({
        client: '',
        therapist: '',
        date: '',
        starttime: '',
        endtime: '',
        telehealth: false,
        room: '',
        reminder: ''
    });

    useEffect(() => {
        // Fetch appointment data based on ID and populate form
        const appointment = data.appointments.find(app => app.id === parseInt(id));
        if (appointment) {
            setFormData({
                client: `${appointment.fname} ${appointment.lname}`,
                therapist: appointment.therapist,
                date: appointment.date,
                starttime: appointment.starttime,
                endtime: appointment.endtime,
                telehealth: appointment.telehealth,
                room: appointment.room,
                reminder: appointment.reminder
            });
        }
    }, [id]);

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#7A65AC', // Change the background color here
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSwitchChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            telehealth: e.target.checked
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your update logic here
        // Update the JSON data with the new formData
        console.log(formData);
    };

    return (
        <>
            <div className=''>
                <div className='flex items-center justify-between bg-darklightgrey'>
                    <div className='container mx-auto flex justify-between py-[20px] px-[40px]'>
                        <h3 className='text-black text-[20px] md:text-[40px] font-bold'>Update Therapist</h3>
                        <div className='flex items-center gap-5'>
                            <button className='bg-primary text-white w-[150px] md:w-[300px] md:flex hidden items-center justify-center py-[15px] rounded-[60px] font-bold text-[23px]' onClick={handleSubmit}>Update</button>
                            <Link to={`${baseUrl}/therapist`} className='text-[20px] font-bold text-primary'><Image src="common/cross-sign.png" alt="logo" width="35" height="30" /></Link>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto md:mx-0 w-[100%] md:w-[80%] p-[20px]">
                    <div className="lg:py-6 lg:px-5 py-3">
                        <form className="space-y-6">
                            <div className="flex flex-col md:flex-row md:space-x-4">
                                <div className="flex-1">
                                    <label htmlFor="firstName" className="block text-primary font-bold text-[20px]">First Name:</label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="flex-1 mt-4 md:mt-0">
                                    <label htmlFor="lastName" className="block text-primary font-bold text-[20px]">Last Name:</label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                            </div>


                            <div className="flex flex-col md:flex-row md:space-x-4">
                                <div className="flex-1">
                                    <label htmlFor="email" className="block text-primary font-bold text-[20px]">Email:</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="flex-1 mt-4 md:mt-0">
                                    <label htmlFor="mobile" className="block text-primary font-bold text-[20px]">Mobile:</label>
                                    <input
                                        type="text"
                                        id="mobile"
                                        name="mobile"
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row md:space-x-4">
                                <div className="flex-1">
                                    <label htmlFor="password" className="block text-primary font-bold text-[20px]">Password:</label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="flex-1 mt-4 md:mt-0">
                                    <label htmlFor="confirmPassword" className="block text-primary font-bold text-[20px]">Confirm Password:</label>
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                            </div>


                            <div className="flex flex-col md:flex-row md:space-x-4">
                                <div className="flex-1 justify-center lg:justify-start">
                                    <label htmlFor="telehealth" className="text-primary font-bold text-[20px] mr-4">Telehealth:</label>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<IOSSwitch id="telehealth" name="telehealth" />}
                                            label="" className='mt-5 pl-4'
                                        />
                                    </FormGroup>
                                </div>
                            </div>


                            <div className="flex flex-col md:flex-row md:space-x-4 lg:w-[60%]">
                                <div className="flex-1">
                                    <label htmlFor="therapistType" className="block text-primary font-bold text-[20px]">Therapist Type:</label>
                                    <select
                                        id="therapistType"
                                        name="therapistType"
                                        className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                                    >
                                        <option value="">Select Type</option>
                                        <option value="type1">Type 1</option>
                                        <option value="type2">Type 2</option>
                                    </select>
                                </div>
                                <div className="flex-1 mt-4 md:mt-0">
                                    <label htmlFor="status" className="block text-primary font-bold text-[20px]">Status:</label>
                                    <select
                                        id="status"
                                        name="status"
                                        className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                                    >
                                        <option value="">Select Status</option>
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </select>
                                </div>
                            </div>


                            <div className="flex flex-col md:flex-row md:space-x-4 justify-start items-start">
                                <div className="flex-1 ">
                                    <input
                                        type="file"
                                        id="profileImage"
                                        name="profileImage"
                                        className="hidden"
                                    />
                                    <label htmlFor="profileImage" className="text-primary font-bold text-[20px] cursor-pointer flex mt-5">
                                        <figure className="flex flex-col items-start ">
                                            <svg width="40" height="34" viewBox="0 0 20 17" className="mb-2" fill="#7A65AC">
                                                <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                                            </svg>
                                            Upload Profile Image
                                        </figure>
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='py-3 lg:px-6 px-0'>
                        <h5 className='text-[20px] text-primary font-bold'>Submited Time Cards </h5>
                        <div className="overflow-x-auto">
                            <table className="table-auto w-full my-5">
                                <thead className='border-t border-b border-gray-200'>
                                    <tr className=''>
                                        <th className='text-left text-tablehead font-normal capitalize'>Date</th>
                                        <th className='text-left text-tablehead font-normal capitalize'>Hours</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='text-[#3b3c51] font-bold text-[17.36px] py-2'></td>
                                        <td className='text-[#3b3c51] font-bold text-[17.36px] py-2'></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default UpdateTherapist;
