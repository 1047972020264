import React, { useState } from 'react'
import Foot from './Foot';
import Head from './Head';

const SignUp = ({ handleSignUp }) => {
    const [useremail, setUseremail] = useState("");
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [number, setNumber] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSignUp(useremail, password, firstName, lastName, number, cPassword);
    };
const docs ="Enter your basic information to create your PurpleCloud account. Provide your first name, last name, email address, and phone number to get started on managing your mental health practice efficiently."
    return (
        <>
            <div className="max-w-[900px] mx-auto px-[15px] flex flex-col gap-2 ">
                <Head title="Account Details" docs={docs}/>
            </div>
            <div className="max-w-[900px] mx-auto px-[15px] flex flex-col gap-2 ">
                <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-y-6 py-5 lg:py-8">
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            required="required"
                            className="w-full border border-lightpurple rounded-[29px] focus:border-primary h-[58px] px-5 placeholder:text-primary text-[18px] outline-primary"
                            placeholder="First Name"
                            autoComplete="on"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            required="required"
                            className="w-full border border-lightpurple rounded-[29px] focus:border-primary h-[58px] px-5 placeholder:text-primary outline-primary text-[18px]"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                        <input
                            type="email"
                            id="email"
                            name="email"
                            required="required"
                            className="w-full border border-lightpurple rounded-[29px] focus:border-primary h-[58px] px-5 placeholder:text-primary text-[18px] outline-primary"
                            placeholder="Email Address"
                            autoComplete="on"
                            value={useremail}
                            onChange={(e) => setUseremail(e.target.value)}
                        />

                        <input
                            type="tel"
                            id="number"
                            name="number"
                            required="required"
                            className="w-full border border-lightpurple rounded-[29px] focus:border-primary h-[58px] px-5 placeholder:text-primary text-[18px] outline-primary"
                            placeholder="Mobile Number"
                            autoComplete="on"
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                        />
                        <input
                            type="password"
                            id="password"
                            name="password"
                            required="required"
                            className="w-full border border-lightpurple rounded-[29px] focus:border-primary h-[58px] px-5 placeholder:text-primary outline-primary text-[18px]"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            id="cpassword"
                            name="cpassword"
                            required="required"
                            className="w-full border border-lightpurple rounded-[29px] focus:border-primary h-[58px] px-5 placeholder:text-primary outline-primary text-[18px]"
                            placeholder="Confirm Password"
                            value={cPassword}
                            onChange={(e) => setCPassword(e.target.value)}
                        />
                    </div>

                    <div className="flex gap-3">
                        <button
                            type="submit"
                            className=" flex text-white bg-primary w-[218px] py-[15px] rounded-[29px] items-center justify-center mx-auto  text-xl font-bold"
                        >
                            Get Started
                        </button>
                    </div>


                </form>
            </div>
            <div className="">
                <Foot />
            </div>
        </>
    );
}

export default SignUp