import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './custom-datepicker.css';
import { FaCalendarAlt, FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';

function DateOfBirthPicker(props) {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (props.selectedDate) {
      setSelectedDate(new Date(props.selectedDate));
    }
  }, [props.selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);

    // Format the date as YYYY/MM/DD
    const formattedDate = date ? date.toISOString().split('T')[0].replace(/-/g, '/') : '';
    props.onChange(formattedDate); // Call the parent component's onChange handler with formatted date
  };

  return (
    <>
      <label htmlFor={props.pickerId} className="block text-primary font-bold text-[20px]">{props.label}</label>
      <div className="relative mt-1">
        <span className='absolute h-full w-14 top-0 right-0 z-10 text-2xl text-primary grid place-items-center border-l border-primary pointer-events-none'>
          <FaCalendarAlt />
        </span>
        <DatePicker
          id={props.pickerId}
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="MM/dd/yyyy"
          placeholderText=""
          autoComplete='off'
          className="block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className="flex justify-between items-center bg-primary gap-1 p-1">
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                className="focus:outline-none bg-white p-1"
              >
                <FaChevronCircleLeft />
              </button>
              <div className="flex gap-2 items-center p-1">
                <select
                  value={date.getMonth()}
                  onChange={({ target: { value } }) => changeMonth(value)}
                  className=" border-none focus:outline-none p-1"
                >
                  {[
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ].map((month, index) => (
                    <option key={index} value={index}>
                      {month}
                    </option>
                  ))}
                </select>
                <select
                  value={date.getFullYear()}
                  onChange={({ target: { value } }) => changeYear(value)}
                  className=" border-none focus:outline-none p-1"
                >
                  {Array.from({ length: 100 }, (_, i) => (
                    <option key={i} value={date.getFullYear() - 50 + i}>
                      {date.getFullYear() - 50 + i}
                    </option>
                  ))}
                </select>
              </div>
              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                className="focus:outline-none bg-white p-1"
              >
                <FaChevronCircleRight />
              </button>
            </div>
          )}
        />
      </div>
    </>
  );
}

export default DateOfBirthPicker;
