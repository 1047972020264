import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TimecardDetail = () => {
    const [timecardData, setTimecardData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            const queryParams = new URLSearchParams(location.search);
            const tcard = queryParams.get('tcard');
            
            if (!tcard) {
                setError('No timecard data provided');
                setLoading(false);
                return;
            }

            setLoading(true);
            setError("");

            try {
                // Fetch the data based on the `tcard` parameter
                const response = await fetch(`https://sandbox.purplecloud.io/api/time-card/details?tcard=${tcard}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setTimecardData(data.timecard || []);
            } catch (err) {
                console.error('Failed to fetch timecard details:', err);
                setError('Failed to fetch timecard details');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [location.search]);

    return (
        <div>
            <h2 className='text-[20px] text-primary font-bold'>Day Sheet</h2>
            {loading && <p>Loading...</p>}
            {error && <p className='text-red-500'>{error}</p>}
            <div className="overflow-x-auto">
                <table className="table-auto w-full my-5">
                    <thead className='border-t border-b border-gray-200'>
                        <tr>
                            <th className='text-left text-tablehead font-normal capitalize'>Date</th>
                            <th className='text-left text-tablehead font-normal capitalize'>Hours</th>
                        </tr>
                    </thead>
                    <tbody>
                        {timecardData.length > 0 ? (
                            timecardData.map((item) => (
                                <tr key={item.uid}>
                                    <td className='text-[#3b3c51] font-bold text-[17.36px] py-2'>{item.tdate}</td>
                                    <td className='text-[#3b3c51] font-bold text-[17.36px] py-2'>{item.working_hours}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2" className='font-bold text-primary text-center py-2'>No data available for this timecard.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TimecardDetail;
