import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@mui/material';
import { Link } from 'react-router-dom';
import { FaEye } from "react-icons/fa";

const baseUrl = "/purple-cloud";

function CustomTabPanel(props) {
    const { children, value, index, className, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={className}
            {...other}
        >
            {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Clients = () => {
    const [value, setValue] = React.useState(0);
    const [activeClients, setActiveClients] = React.useState([]);
    const [inactiveClients, setInactiveClients] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        const fetchClients = async () => {
            const token = localStorage.getItem('token');
    
            try {
                const response = await fetch('https://sandbox.purplecloud.io/api/clients', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token }), // Send token in body
                });
    
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
    
                const data = await response.json();

                // Map the response data correctly
                setActiveClients(data.data.active_clients || []);
                setInactiveClients(data.data.inactive_clients || []);
            } catch (err) {
                setError('Failed to fetch clients.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
    
        fetchClients();
    }, []);
    

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <>
            <div className='flex items-center justify-between'>
                <h2 className='text-[20px] text-primary font-bold mb-5'>Clients</h2>
                <Link to={`${baseUrl}/clients/new-client`} className='md:w-[173px] p-3 border-2 border-primary text-white rounded-[60px] flex items-center justify-center py-1 font-bold bg-primary transition'>
                    New Client
                </Link>
            </div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Active" {...a11yProps(0)} sx={{ fontSize: '20px', color: '#7A65AC !important', fontWeight: 'bold' }} />
                        <Tab label="Inactive" {...a11yProps(1)} sx={{ fontSize: '20px', color: '#7A65AC !important', fontWeight: 'bold' }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0} className="your-tab-panel-class">
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead className="border-t border-b border-gray-200">
                                <tr>
                                    <th className="text-left text-tablehead font-normal">NAME</th>
                                    <th className="text-left text-tablehead font-normal">ID</th>
                                    <th className="text-left text-tablehead font-normal">DOB</th>
                                    <th className="text-left text-tablehead font-normal">PHONE</th>
                                    <th className="text-left text-tablehead font-normal">EMAIL</th>
                                    <th className="text-left text-tablehead font-normal">DIAGNOSIS</th>
                                    <th className="text-left text-tablehead font-normal">THERAPIST</th>
                                    <th className="text-left text-tablehead font-normal">VIEW</th>
                                </tr>
                            </thead>
                            <tbody>
                                {activeClients.map(client => (
                                    <tr key={client.client_id}>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px] py-5">{client.fname} {client.lname}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">{client.client_id}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">{client.dob || 'N/A'}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">{client.mob1 || 'N/A'}</td>
                                        <td className="font-bold text-[17.36px] underline text-[#cfb2e7]">{client.eml || 'N/A'}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">{client.diagnosis || 'N/A'}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">{client.tfName || 'N/A'}</td>
                                        <td>
                                            <Link to={`${baseUrl}/clients/client-details/${client.client_id}`} className='text-[20px] font-bold text-primary'><FaEye /></Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1} className="your-tab-panel-class">
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead className="border-t border-b border-gray-200">
                                <tr>
                                    <th className="text-left text-tablehead font-normal">NAME</th>
                                    <th className="text-left text-tablehead font-normal">ID</th>
                                    <th className="text-left text-tablehead font-normal">DOB</th>
                                    <th className="text-left text-tablehead font-normal">PHONE</th>
                                    <th className="text-left text-tablehead font-normal">EMAIL</th>
                                    <th className="text-left text-tablehead font-normal">DIAGNOSIS</th>
                                    <th className="text-left text-tablehead font-normal">THERAPIST</th>
                                    <th className="text-left text-tablehead font-normal">VIEW</th>
                                </tr>
                            </thead>
                            <tbody>
                                {inactiveClients.map(client => (
                                    <tr key={client.client_id}>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px] py-5">{client.fname} {client.lname}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">{client.client_id}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">{client.dob || 'N/A'}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">{client.mob1 || 'N/A'}</td>
                                        <td className="font-bold text-[17.36px] underline text-[#cfb2e7]">{client.eml || 'N/A'}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">{client.diagnosis || 'N/A'}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">{client.tfName || 'N/A'}</td>
                                        <td>
                                            <Link to="/logout" className='text-[20px] font-bold text-primary'><FaEye /></Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CustomTabPanel>
            </Box>
        </>
    );
}

export default Clients;
