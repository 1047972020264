import React, { useState, useEffect } from 'react'
import Image from '../../components/image/Image'
import { FaSearch } from 'react-icons/fa'
import { CgMenu } from "react-icons/cg";
import { Link } from 'react-router-dom'

const Header = ({ openMenu ,handleLogout }) => {
  const [first, setFirst] = useState(false); // Initialize the state

  // Use useEffect to call openMenu whenever `first` changes
  useEffect(() => {
    openMenu(first);
  }, [first, openMenu]);

  return (
    <>
      <header>
        <div className="bg-primary p-3 text-white flex items-center justify-between gap-3 h-[72px]">
          <div className="flex items-center gap-3 w-full lg:w-[750px]">
            <div className="flex-none w-11">
              <Image src="header/header-logo.png" alt="logo" width="45" height="30" />
            </div>
            <form action="" className="flex-1">
              <div className="relative w-full">
                <input type="text" name="" id="" placeholder='Search Client' className='h-10 w-full rounded-3xl pr-9 pl-3 focus:outline-none text-primary placeholder:text-primary' />
                <FaSearch className='absolute right-3 top-0 bottom-0 my-auto text-primary' />
              </div>
            </form>
          </div>
          <div className="flex items-center justify-between gap-2 flex-none">
            <ul className='flex items-center justify-between gap-2'>
              <li className='hidden md:block'>
                <Link to="/profile">Head</Link>
              </li>
              <li className='hidden md:block'>|</li>
              <li className='hidden md:block'>
                <span className='cursor-pointer' onClick={handleLogout}>Log Out</span>
              </li>
              <li>
                <Image src="header/img.png" alt="logo" width="45" height="45" imageClass="rounded-full object-cover object-center w-11 h-11" />
              </li>
            </ul>
            <button onClick={() => setFirst(prev => !prev)} className='lg:hidden'>
              <CgMenu className='text-4xl' />
            </button>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header;
