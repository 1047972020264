import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import Image from '../../components/image/Image';
import { useNavigate } from 'react-router-dom';

const baseUrl = '/purple-cloud';


// Function to format date and time
const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { month: 'numeric', day: 'numeric', year: '2-digit' };
    return date.toLocaleDateString('en-US', options);
};

const convertDateFormat = (dateStr) => {
    if (!dateStr) return ''; // Handle empty string or null

    const parts = dateStr.split('/');
    if (parts.length !== 3) {
        console.error('Unexpected date format:', dateStr);
        return '';
    }

    const [month, day, year] = parts;

    // Ensure that month, day, and year are defined and valid
    if (!month || !day || !year) {
        console.error('Invalid date components:', parts);
        return '';
    }

    // Return in YYYY-MM-DD format
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};


const UpdateInsurenceVerification = () => {
    const { ins_id } = useParams();
    const [insurance, setInsurance] = useState(null); // Initialize as null
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token');
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();

    const convertDateFormat = (dateStr) => {
        if (typeof dateStr !== 'string') {
            console.error('Expected a string but got:', typeof dateStr);
            return '';
        }
    
        const parts = dateStr.split('/');
        if (parts.length !== 3) {
            console.error('Unexpected date format:', dateStr);
            return '';
        }
    
        const [month, day, year] = parts;
    
        if (!month || !day || !year) {
            console.error('Invalid date components:', parts);
            return '';
        }
    
        // Pad the month and day with leading zeros if necessary
        const paddedMonth = month.padStart(2, '0');
        const paddedDay = day.padStart(2, '0');
        const paddedYear = year.padStart(4, '0');
    
        // Return the formatted date
        return `${paddedYear}-${paddedMonth}-${paddedDay}`;
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ 
            ...prevState, 
            [name]: name === 'policy_holder_dob' ? value : value 
        }));
    };
    
    

    useEffect(() => {
        if (!token) {
            setError('Token is missing. Please log in again.');
            setLoading(false);
            return;
        }
    
        if (!ins_id) {
            setError('Insurance ID is missing.');
            setLoading(false);
            return;
        }
    
        const fetchInsuranceData = async () => {
            try {
                const response = await fetch('https://sandbox.purplecloud.io/api/insurance-verification/get-update', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token, ins_id }), // Pass both token and ins_id
                });
    
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
    
                const data = await response.json();
    
                if (data.status === 'success') {
                    console.log(data.data.data);
                    const insuranceData = data.data.data;
                    setInsurance(data.data.data);
                    // Initialize formData based on insurance data
                    setFormData({
                        ...insuranceData,
                        policy_holder_dob: insuranceData.policy_holder_dob ? convertDateFormat(insuranceData.policy_holder_dob) : ''
                    });
                } else {
                    setError('Failed to fetch insurance details.');
                }
            } catch (err) {
                setError(`Failed to fetch insurance details: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };
    
        fetchInsuranceData();
    }, [ins_id, token]);
    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!token) {
            setError('Token is missing.');
            return;
        }
    
        try {
            const response = await fetch('https://sandbox.purplecloud.io/api/insurance-verification/update-insurance', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    token, 
                    ins_id,
                    clientId: formData.clientId,  // Ensure clientId is included
                    ...formData 
                }), 
            });
    
            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
    
            const data = await response.json();
    
            if (data.status === 'success') {
                alert('Insurance information updated successfully.');
                navigate(`${baseUrl}/insurence-verification`);
            } else {
                setError('Failed to update insurance details.');
            }
        } catch (err) {
            setError(`Failed to update insurance details: ${err.message}`);
        }
    };
    
    
    
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    if (!insurance) {
        return <div>No data available.</div>;
    }

    return (
        <>
            <div className='-mx-3 -mt-5 md:-mt-[30px]'>
                <div className='flex items-center justify-between bg-darklightgrey'>
                    <div className='container mx-auto flex justify-between py-[20px] px-[40px]'>
                        <h3 className='text-black text-[20px] md:text-[40px] font-bold'>Update Insurance</h3>
                        <div className='flex items-center gap-5'>
                            <button type="submit" className='bg-primary text-white w-[150px] md:w-[300px] md:flex hidden items-center justify-center py-[15px] rounded-[60px] font-bold text-[23px]' onClick={handleSubmit}>Update</button>
                            <Link to={`${baseUrl}/insurence-verification`} className='text-[20px] font-bold text-primary'><Image src="common/cross-sign.png" alt="logo" width="35" height="30" /></Link>
                        </div>
                    </div>
                </div>
                <div className='container mx-auto md:mx-0 w-[100%] p-[20px]'>
                    <div className="lg:p-6">
                        <form className="space-y-6" onSubmit={handleSubmit} method='POST'>
                            <input type="hidden" name="clientId" value={formData.client_id}/>
                            <div className="flex flex-col md:flex-row md:space-x-4 md:w-7/12">
                                <div className="flex-1">
                                    <label htmlFor="payment_type" className="block text-primary font-bold text-[20px]">Type:</label>
                                    <select
                                        id="payment_type"
                                        name="payment_type"
                                        value={formData.payment_type || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                                    >
                                        <option value="" selected="" disabled="">Select type</option>
                                        <option value="Primary">Primary </option>
                                        <option value="Secondary">Secondary </option>
                                        <option value="Self Paid">Self Paid </option>
                                        <option value="EAP (Employee Assistance Program)">EAP (Employee Assistance Program) </option>
                                    </select>
                                </div>
                                <div className="flex-1 mt-4 md:mt-0">
                                    <label htmlFor="carrier_name" className="block text-primary font-bold text-[20px]">Carrier Name:</label>
                                    <input
                                        type="text"
                                        id="carrier_name"
                                        name="carrier_name"
                                        value={formData.carrier_name || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row md:space-x-4 md:w-6/12">
                                <div className="flex-1 mt-4 md:mt-0">
                                    <label htmlFor="carrier_phone" className="block text-primary font-bold text-[20px]">Carrier Phone:</label>
                                    <input
                                        type="text"
                                        id="carrier_phone"
                                        name="carrier_phone"
                                        value={formData.carrier_phone || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row md:space-x-4 lg:gap-14">
                                <div className="flex-1">
                                    <label htmlFor="id" className="block text-primary font-bold text-[20px]">ID#:</label>
                                    <input
                                        type="text"
                                        id="id"
                                        name="id"
                                        value={formData.id || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="flex-1 mt-4 md:mt-0">
                                    <label htmlFor="group_id" className="block text-primary font-bold text-[20px]">Group ID:</label>
                                    <input
                                        type="text"
                                        id="group_id"
                                        name="group_id"
                                        value={formData.group_id || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row md:space-x-4 lg:gap-14">
                                <div className="flex-1">
                                    <label htmlFor="carrier_name" className="block text-primary font-bold text-[20px]">Policy Holder Name:</label>
                                    <input
                                        type="text"
                                        id="carrier_name"
                                        name="carrier_name"
                                        value={formData.carrier_name || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="flex-1 mt-4 md:mt-0">
                                    <label htmlFor="policy_holder_dob" className="block text-primary font-bold text-[20px]">Policy Holder DOB:</label>
                                    <input
                                        type="date"
                                        id="policy_holder_dob"
                                        name="policy_holder_dob"
                                        value={formData.policy_holder_dob || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row md:space-x-4 lg:gap-14">
                                <div className="flex-1">
                                    <label htmlFor="policy_holder_relation_to_client" className="block text-primary font-bold text-[20px]">Policy Holder Relation to Client:</label>
                                    <input
                                        type="text"
                                        id="policy_holder_relation_to_client"
                                        name="policy_holder_relation_to_client"
                                        value={formData.policy_holder_relation_to_client || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="flex-1 mt-4 md:mt-0">
                                    <label htmlFor="policy_holder_employer" className="block text-primary font-bold text-[20px]">Policy Holder Employer:</label>
                                    <input
                                        type="text"
                                        id="policy_holder_employer"
                                        name="policy_holder_employer"
                                        value={formData.policy_holder_employer || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                            </div>

                           <div className="flex flex-col md:flex-row md:space-x-4 lg:gap-14">
                                <div className="flex-1">
                                    <label htmlFor="deductable" className="block text-primary font-bold text-[20px]">Deductable:</label>
                                    <input
                                        type="number"
                                        id="deductable"
                                        name="deductable"
                                        value={formData.deductable || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="flex-1 mt-4 md:mt-0">
                                    <label htmlFor="outstanding_balance_due" className="block text-primary font-bold text-[20px]">Outstanding Balance Due: </label>
                                    <input
                                        type="number"
                                        id="outstanding_balance_due"
                                        name="outstanding_balance_due"
                                        value={formData.outstanding_balance_due || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                            </div>

                           <div className="flex flex-col md:flex-row md:space-x-4 lg:gap-14">
                                <div className="flex-1">
                                    <label htmlFor="coinsurance" className="block text-primary font-bold text-[20px]">Coinsurance:</label>
                                    <input
                                        type="text"
                                        id="coinsurance"
                                        name="coinsurance"
                                        value={formData.coinsurance || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="flex-1 mt-4 md:mt-0">
                                    
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row md:space-x-4 lg:gap-14">
                                <div className="flex-1">
                                    <label htmlFor="days_allowed_per_year" className="block text-primary font-bold text-[20px]">Days Allowed Per Year:</label>
                                    <input
                                        type="text"
                                        id="days_allowed_per_year"
                                        name="days_allowed_per_year"
                                        value={formData.days_allowed_per_year || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                                <div className="flex-1 mt-4 md:mt-0">
                                <label htmlFor="lifetime_max" className="block text-primary font-bold text-[20px]">Lifetime Max:</label>
                                    <input
                                        type="text"
                                        id="lifetime_max"
                                        name="lifetime_max"
                                        value={formData.lifetime_max || ''}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row md:space-x-4 ">
                                <div className="flex-1">
                                    <label htmlFor="notes_insurance" className="block text-primary font-bold text-[20px]">Notes:</label>
                                    
                                    <textarea name="notes_insurance" id="notes_insurance" className="mt-1 block w-full shadow-sm focus:border-indigo-300 resize-none h-[145px] md:h-[163px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none  focus:ring-0 px-5 text-primary" value={formData.notes_insurance || ''}  onChange={handleInputChange}></textarea>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpdateInsurenceVerification;
