import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RiDeleteBin6Fill } from "react-icons/ri";

import DeleteAppointment from '../../components/delete/DeleteAppointment';
const baseUrl = "https://sandbox.purplecloud.io";

const DocumentLibrary = () => {
  const [deleteShow, setDeleteShow] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentToDelete, setDocumentToDelete] = useState(null);

  useEffect(() => {
    const fetchDocumentData = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`https://sandbox.purplecloud.io/api/document-library`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }), 
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setDocuments(data.arrDocumentsLibrary);
        
      } catch (err) {
        console.error('Failed to fetch document data:', err);
      }
    };

    fetchDocumentData();
  }, []);

  const handleDelete = async (uid) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this document?");
  
    if (!confirmDelete) {
      return;
    }
  
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`https://sandbox.purplecloud.io/api/document-library/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, uid }), 
      });

      if (!response.ok) {
        throw new Error('Failed to delete document');
      }

      const result = await response.json();


      if (result.status === 'success') {
        setDocuments((prevDocuments) => prevDocuments.filter((doc) => doc.uid !== uid));
        setDeleteShow(false);
        alert('Document deleted successfully');
      }

    } catch (err) {
      console.error('Failed to delete document:', err);
    }
  };

  const handleDownload = (documentFileName) => {
    const url = `${baseUrl}/documents_library/${documentFileName}`;
  
  
    const a = document.createElement('a');
    a.href = url;
    a.download = documentFileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <div className='flex items-center justify-between '>
        <h2 className='text-[20px] text-primary font-bold'>Document Library</h2>
        <Link to={`${baseUrl}/document-library/add`} className='p-3 border-2 border-primary text-white rounded-[60px] flex items-center justify-center py-1 font-bold bg-primary transition'>
          Add New Document
        </Link>
      </div>
      <div className="overflow-x-auto">
        <table className="table-auto w-full my-5">
          <thead className='border-t border-b border-gray-200'>
            <tr>
              <th className='text-left text-tablehead font-normal capitalize text-[16px]'>DOCUMENT TITLE</th>
              <th className='text-tablehead font-normal capitalize text-[16px] text-center'>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((document, index) => (
              <tr key={index}>
                <td className='text-[#3b3c51] font-bold text-[17.36px] py-2'>{document.document_title}</td>
                <td className='text-[#3b3c51] font-bold text-[17.36px] py-2'>
                  <div className='flex items-center justify-around'>
                    <button
                      className='text-[20px] font-bold text-primary'
                      onClick={() => handleDownload(document.document_file_name)}
                    >
                      Download
                    </button>
                    <Link
                      to="#"
                      className='text-[20px] font-bold text-primary'
                      onClick={(e) => {
                        e.preventDefault();
                        handleDelete(document.uid); 
                      }}
                    >
                      <RiDeleteBin6Fill />
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {deleteShow && (
        <DeleteAppointment
          showDelete={deleteShow}
          documentUid={documentToDelete}
          onClose={() => setDeleteShow(false)}
        />
      )}
    </>
  );
};

export default DocumentLibrary;
