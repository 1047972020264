import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Button, Typography, Modal } from '@mui/material';
import { Link } from 'react-router-dom';
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Fill } from "react-icons/ri";
import useFetch from '../../api/useFetch';
const baseUrl = '/purple-cloud';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: '2px solid #7A65AC',
  boxShadow: 24,
  outline: 0,
  p: 4,
  textAlign: 'left',
  color: '#7A65AC',
};

const buttonStyle = {
  borderRadius: '30px',
  fontSize: '16px',
  padding: '10px 20px',
  textTransform: 'none',
  backgroundColor: '#7A65AC',
  '&:hover': {
    backgroundColor: '#6B5C9E',
  },
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Therapist = () => {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  // Use the updated useFetch hook with a polling interval
  const { data, loading, error } = useFetch('https://sandbox.purplecloud.io/api/therapist', 10000);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  // Verify data structure
  const activeTherapists = data?.data?.activeTherapists || [];
  const inactiveTherapists = data?.data?.inactiveTherapists || [];

  const renderTableRows = (therapists) => {
    return therapists.map((therapist) => (
      <tr key={therapist.doc_id}>
        <td>{therapist.lname}</td>
        <td>{therapist.fname}</td>
        <td>{therapist.mobile}</td>
        <td>{therapist.email}</td>
        <td>
          <div className="flex gap-3">
            <Link to={`/therapist/edit/${therapist.doc_id}`} className="text-primary hover:text-primary">
              <FiEdit />
            </Link>
            <button onClick={() => handleDelete(therapist.doc_id)} className="text-primary hover:text-primary ml-2">
              <RiDeleteBin6Fill />
            </button>
          </div>
        </td>
      </tr>
    ));
  };

  const handleDelete = (id) => {
    // Add logic to handle delete action
    console.log(`Delete therapist with id: ${id}`);
  };

  return (
    <>
      <div className='flex items-center justify-between'>
        <h2 className='text-[20px] text-primary font-bold mb-5'>Therapist</h2>
        <Button
          onClick={handleOpen}
          sx={{
            ...buttonStyle,
            width: { md: '173px', xs: '100px' },
            border: '2px solid #7A65AC',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            py: 1,
            fontWeight: 'bold',
            backgroundColor: '#7A65AC',
            transition: 'background-color 0.3s',
          }}
        >
          Add Therapist
        </Button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Add Therapist
          </Typography>
          <Typography sx={{ mt: 2, fontSize: '14px', color: '#7A65AC' }}>
            By adding a Therapist, you agree to an additional $29 per month being added to your subscription.
          </Typography>
          <Box mt={2} display="flex" justifyContent="flex-start" gap={2}>
            <Link to={`${baseUrl}/therapist/create-team`}>
              <Button variant="contained" color="primary" sx={{ ...buttonStyle, px: 5 }}>
                Add Therapist
              </Button>
            </Link>

            <Button onClick={handleClose} variant="contained" sx={{ ...buttonStyle, bgcolor: '#E0B3FF', px: 5 }}>
              Not Now
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Active" {...a11yProps(0)} sx={{ fontSize: '20px', color: '#7A65AC !important', fontWeight: 'bold' }} />
            <Tab label="Inactive" {...a11yProps(1)} sx={{ fontSize: '20px', color: '#7A65AC !important', fontWeight: 'bold' }} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              <thead className="border-t border-b border-gray-200">
                <tr>
                  <th className="text-left text-tablehead font-normal capitalize">Last Name</th>
                  <th className="text-left text-tablehead font-normal capitalize">First Name</th>
                  <th className="text-left text-tablehead font-normal capitalize">Phone</th>
                  <th className="text-left text-tablehead font-normal capitalize">Email</th>
                  <th className="text-left text-tablehead font-normal capitalize">Action</th>
                </tr>
              </thead>
              <tbody>
                {activeTherapists.length ? (
                  renderTableRows(activeTherapists)
                ) : (
                  <tr>
                    <td colSpan="5" className='font-bold text-primary text-center py-2'>No active therapists available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              <thead className="border-t border-b border-gray-200">
                <tr>
                  <th className="text-left text-tablehead font-normal capitalize">Last Name</th>
                  <th className="text-left text-tablehead font-normal capitalize">First Name</th>
                  <th className="text-left text-tablehead font-normal capitalize">Phone</th>
                  <th className="text-left text-tablehead font-normal capitalize">Email</th>
                  <th className="text-left text-tablehead font-normal capitalize">Action</th>
                </tr>
              </thead>
              <tbody>
                {inactiveTherapists.length ? (
                  renderTableRows(inactiveTherapists)
                ) : (
                  <tr>
                    <td colSpan="5" className='font-bold text-primary text-center py-2'>No inactive therapists available.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default Therapist;
