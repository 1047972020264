import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
const baseUrl = "/purple-cloud";

const Timecard = () => {
    const [timecardData, setTimecardData] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Default to current year
    const [message, setMessage] = useState("");

    useEffect(() => {
        const fetchTimecardData = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await fetch('https://sandbox.purplecloud.io/api/time-card', { 
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        token, 
                        year: selectedYear 
                    }), 
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                if (data.msg) {
                    setMessage(data.msg);
                    setTimecardData([]); 
                } else {
                    setMessage(""); 
                    setTimecardData(data.timecard || []);
                }

            } catch (err) {
                console.error('Failed to fetch timecard data:', err);
            }
        };

        fetchTimecardData();
    }, [selectedYear]); 

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value); 
    };

    const handleTimecardPreview = async (tcard) => {
        const token = localStorage.getItem('token');
       
        const daysheetId = null; 
    
        try {
            const response = await fetch('https://sandbox.purplecloud.io/api/day-sheet', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    daysheet_id: daysheetId,
                    tcard,
                }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
            if (data.msg) {
                setMessage(data.msg);
                // Set additional state if needed
            } else {
                setMessage("");
                // Set additional state if needed
            }
        } catch (err) {
            console.error('Failed to fetch daysheet data:', err);
        }
    };

    const formatDate = (dateString) => {
        const month = dateString.slice(0, 2);
        const year = dateString.slice(2);
        return `${parseInt(month, 10)}/${year.slice(-2)}`;
    };

    return (
        <>
            <div className='flex gap-5 justify-between'>
                <h2 className='text-[20px] text-primary font-bold'>Time Cards</h2>
                <div>
                    <form>
                        <div className='flex gap-3'>
                            <select
                                name="year"
                                id="year"
                                className='border-2 px-2 py-1 border-primary rounded-[40px] text-[14px] text-primary font-bold focus:outline-none cursor-pointer shadow-md'
                                value={selectedYear}
                                onChange={handleYearChange}
                            >
                                <option value="">Select Year</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="table-auto w-full my-5">
                    <thead className='border-t border-b border-gray-200'>
                        <tr>
                            <th className='text-left text-tablehead font-normal capitalize'>Date</th>
                            <th className='text-left text-tablehead font-normal capitalize'>Hours</th>
                            <th className='text-left text-tablehead font-normal'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {message && (
                            <tr>
                                <td colSpan="3" className='font-bold text-primary text-center py-2'>{message}</td>
                            </tr>
                        )}
                        {timecardData.length > 0 ? (
                            timecardData.map((item) => (
                                <tr key={item.uid}>
                                    <td className='text-[#171718] font-bold text-[17.36px] py-2'>{formatDate(item.tdate)}</td>
                                    <td className='text-[#3b3c51] font-bold text-[17.36px] py-2'>{item.working_hours}</td>
                                    <td className='text-[#3b3c51] font-bold text-[17.36px] py-2 flex items-center justify-end'>
                                    <Link to={`${baseUrl}/day-sheet?tcard=${formatDate(item.tdate)}`}
                                        onClick={() => handleTimecardPreview(formatDate(item.tdate))}
                                        className='p-3 border-2 border-primary text-primary rounded-[60px] flex items-center justify-center py-1 font-bold hover:bg-primary hover:text-white transition text-[12px] md:text-[15px]'>
                                        Preview
                                    </Link>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="3" className='font-bold text-primary text-center py-2'>No timecards match this year.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Timecard;
