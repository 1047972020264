import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Image = (props) => {
    return (
        <>
            <LazyLoadImage
                alt={props.alt}
                height={props.height}
                src={`${process.env.PUBLIC_URL}/assets/images/${props.src}`}
                width={props.width}
                className={props.imageClass} />
            {props.caption === '' ? "" : <span>{props.caption}</span>}
        </>
    )
}

export default Image