import React, { useState, useEffect } from 'react';
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import AppointmentsData from '../../components/appointmentsData/AppointmentsData';

const baseUrl = "/purple-cloud";

const Dashboard = () => {
  const [appointments, setAppointments] = useState([]);
  const [openNotes, setOpenNotes] = useState([]);
  const [daySheet, setDaySheet] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false); 
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const fetchAppointmentData = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`https://sandbox.purplecloud.io/api/dashboard`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }), // Send token in body
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        
        // Ensure data has the expected structure before setting state
        setAppointments(data?.data?.appointment || []);
        setOpenNotes(data?.data?.open_notes || []);
        setDaySheet(data?.data?.daysheet || []);

      } catch (err) {
        console.error('Failed to fetch appointment data:', err);
        alert('Failed to fetch appointment data.');
      }
    };

    fetchAppointmentData();
  }, []);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar); // Toggle calendar visibility
  };

  // Helper function to format date and time
  const formatDateTime = (dateTimeStr) => {
    const optionsDate = { month: 'numeric', day: 'numeric', year: '2-digit' };
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };

    const date = new Date(dateTimeStr);
    const formattedDate = date.toLocaleDateString('en-US', optionsDate);
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
    
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <>
      <div className='flex gap-5 flex-col'>
        <div>
          <div className="flex gap-5">
            <h2 className='text-[20px] text-primary font-bold'>Upcoming Appointments</h2>
            <AppointmentsData />
          </div>
          <div className="overflow-x-auto">
            <table className="table-auto w-full my-5">
              <thead className='border-t border-b border-gray-200'>
                <tr>
                  <th className='w-1/10 text-left text-tablehead font-normal'>Room</th>
                  <th className='w-2/10 text-left text-tablehead font-normal'>Time</th>
                  <th className='w-2/10 text-left text-tablehead font-normal'>Client</th>
                  <th className='w-3/5 text-left text-tablehead font-normal'></th>
                </tr>
              </thead>
              <tbody>
                {appointments.length ? appointments.map((appointment, index) => (
                  <tr key={index}>
                    <td>
                     
                      <span
                        className='flex'
                        style={{
                          backgroundColor: '#049AE4',
                          borderRadius: '50%',
                          height: '30px',
                          width: '30px',
                          margin: '2px',
                        }}
                      ></span>
                    </td>
                    <td className='text-[#3b3c51] font-bold text-[17.36px]'>
                      {formatDateTime(appointment.appDate)}
                    </td>
                    <td className='text-[#3b3c51] font-bold text-[17.36px]'>
                      {appointment.fname + " " + appointment.lname}
                    </td>
                    <td>
                      <div className='flex gap-3 items-center justify-end'>
                        <Link
                          to={`${baseUrl}/appointment/update_appointment/${appointment.app_id}`}
                          className='text-[20px] font-bold text-primary'
                        >
                          <MdOutlineEdit />
                        </Link>
                        <Link to="#" className='text-[20px] font-bold text-primary'>
                          <RiDeleteBin6Fill />
                        </Link>
                        <Link
                          to={`${baseUrl}/clients/client-details/${appointment.client_id}`}
                          className='p-3 border-2 border-primary text-primary rounded-[60px] flex items-center justify-center py-1 font-bold hover:bg-primary hover:text-white transition'
                        >
                          View Client
                        </Link>
                        <Link
                          to={`${baseUrl}/note?clientId=${appointment.client_id}&appId=${appointment.app_id}`}
                          className='p-3 border-2 border-primary text-white rounded-[60px] flex items-center justify-center py-1 font-bold bg-primary transition'
                        >
                          Add Note
                        </Link>
                      </div>
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan="4" className="text-center">No appointments available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className='flex gap-5'>
            <h2 className='text-[20px] text-primary font-bold'>Open Notes</h2>
          </div>
          <div className="overflow-x-auto">
            <table className="table-auto w-full my-5">
              <thead className='border-t border-b border-gray-200'>
                <tr>
                  <th className='w-1/10 text-left text-tablehead font-normal'>Room</th>
                  <th className='w-2/10 text-left text-tablehead font-normal'>Time</th>
                  <th className='w-2/10 text-left text-tablehead font-normal'>Client</th>
                  <th className='w-3/5 text-left text-tablehead font-normal'></th>
                </tr>
              </thead>
              <tbody>
                {openNotes.length ? openNotes.map((note, index) => (
                  <tr key={index}>
                    <td>  
                    <span
                      className='flex'
                      style={{
                        backgroundColor: note.room_id === '0' ? '#049AE4' :
                                        note.room_id === '1' ? '#FFEBCD' :
                                        note.room_id === '2' ? '#34B478' : '#CCCCCC', 
                        borderRadius: '50%',
                        height: '30px',
                        width: '30px',
                        margin: '2px',
                      }}
                    ></span>
                     
                    </td>
                    <td className='text-[#3b3c51] font-bold text-[17.36px]'>
                      {formatDateTime(note.appDate)}
                    </td>
                    <td className='text-[#3b3c51] font-bold text-[17.36px]'>
                      {note.fname + " " + note.lname}
                    </td>
                    <td>
                      <div className='flex gap-3 items-center justify-end'>
                        <Link
                          to={`${baseUrl}/appointment/update_appointment/${note.app_id}`}
                          className='text-[20px] font-bold text-primary'
                        >
                          <MdOutlineEdit />
                        </Link>
                        <Link to="#" className='text-[20px] font-bold text-primary'>
                          <RiDeleteBin6Fill />
                        </Link>
                        <Link
                          to={`${baseUrl}/clients/client-details/${note.client_id}`}
                          className='p-3 border-2 border-primary text-primary rounded-[60px] flex items-center justify-center py-1 font-bold hover:bg-primary hover:text-white transition'
                        >
                          View Client
                        </Link>
                        <Link
                          to={`${baseUrl}/note?clientId=${note.client_id}&appId=${note.app_id}`}
                          className='p-3 border-2 border-primary text-white rounded-[60px] flex items-center justify-center py-1 font-bold bg-primary transition'
                        >
                          Add Note
                        </Link>
                      </div>
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan="4" className="text-center">No open notes available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className='flex gap-5'>
            <h2 className='text-[20px] text-primary font-bold'>Open Day Sheets</h2>
          </div>
          <div className="overflow-x-auto">
            <table className="table-auto w-full my-5 table-s">
              <thead className='border-t border-b border-gray-200'>
                <tr>
                  <th className='text-left text-tablehead font-normal'>Date</th>
                  <th className='text-left text-tablehead font-normal'>Action</th>
                </tr>
              </thead>
              <tbody>
                {daySheet.length ? daySheet.map((sheet, index) => (
                  <tr key={index} className='flex items-center justify-between'>
                    <td className='font-bold'>{sheet.cdate}</td>
                    <td>
                      <Link
                        to={`${baseUrl}/day-sheet/${sheet.daysheet_id}`}
                        className='w-[173px] border-2 border-primary rounded-[60px] flex items-center justify-center py-1 bg-white text-primary transition font-bolder'
                      >
                        Preview
                      </Link>
                    </td>
                  </tr>
                )) : (
                  <tr>
                    <td colSpan="2" className="text-center">No day sheets available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
