import React, { useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { MdOutlineEdit } from 'react-icons/md'
import { Link } from 'react-router-dom'

const Preview = () => {
    const [note, setNote] = useState(false)
    return (
        <>
            <div className="px-3">
                <h3 className='text-[#3b3c51] text-[30px]'>
                    Head, Head
                </h3>
                <div className="flex gap-3 justify-between items-center">
                    <div className="">
                        <p className='text-primary text-xl font-bold'>
                            Day Sheet - 7/23/24 - Hours: 1
                        </p>
                    </div>
                    <div className="">
                        <Link to="/logout" className=' p-3 border-2 md:w-[170px] border-primary text-white rounded-[60px] flex items-center justify-center py-1 font-bold bg-primary transition'>
                            Submit
                        </Link>
                    </div>
                </div>
            </div>
            <div className="overflow-x-auto px-3">
                <table className="table-auto w-full my-5">
                    <thead className='border-t border-b border-gray-200'>
                        <tr className=''>
                            <th className='w-1/10 text-center text-tablehead font-normal'>TIME</th>
                            <th className='w-2/10 text-center text-tablehead font-normal'>CLIENT</th>
                            <th className='w-2/10 text-center text-tablehead font-normal'>DIAGNOSIS</th>
                            <th className='w-3/5 text-center text-tablehead font-normal'>BILLING CODE</th>
                            <th className='w-3/5 text-center text-tablehead font-normal'>CO-PAY</th>
                            <th className='w-3/5 text-center text-tablehead font-normal'>TH CO-PAY</th>
                            <th></th>
                            <th className='w-3/5 text-center text-tablehead font-normal'>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='text-[#3b3c51] font-bold text-[17.36px]'>1:00am-2:00am</td>
                            <td className='text-[#3b3c51] font-bold text-[17.36px]'>
                                AAA, Samir
                            </td>
                            <td className='text-[#3b3c51] font-bold text-[17.36px]'>

                            </td>
                            <td className='text-[#3b3c51] font-bold text-[17.36px]'>
                                90791 - Diagnostic Evaluation
                            </td>
                            <td className='text-[#3b3c51] font-bold text-[17.36px]'>
                                ($0.00) Cash
                            </td>
                            <td className='text-[#3b3c51] font-bold text-[17.36px]'>
                                ($0.00)
                            </td>
                            <td>
                                <span className='flex justify-center items-center gap-2 text-primary cursor-pointer' onClick={()=>setNote(!note)}>
                                    <span className='text-sm'>View Notes</span> <FaChevronRight className={`font-bold text-2xl ${note === true?"rotate-90":"rotate-0"}`} />
                                </span>
                            </td>
                            <td className=''>

                                <div className='flex gap-3 items-center justify-start'>
                                    <Link to="/logout" className='md:text-[20px] text-[12px] font-bold text-primary'><MdOutlineEdit /></Link>
                                </div>
                            </td>
                        </tr>
                        <tr className={note? '':'hidden'}>
                            <td colspan="7">
                                <div className="">
                                    <p className='text-gray-500 text-sm pb-1'>
                                        Session Note:
                                    </p>
                                    <p className='text-[#3b3c51]'>
                                        asdsadasd
                                    </p>
                                    <hr className='border-b-[1px] border=[#f1f1f1] my-4' />
                                    <p className='text-gray-500 text-sm pb-1'>
                                        Billing Note:
                                    </p>
                                    <p className='text-[#3b3c51]'>
                                        sadsad
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Preview