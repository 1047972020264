import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Image from '../../components/image/Image';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Link } from 'react-router-dom';

const baseUrl = "/purple-cloud";

function UpdateAppointment() {
  const { id } = useParams(); // Get the appointment ID from the URL

  const [clientOptions, setClientOptions] = useState([]);
  const [therapistOptions, setTherapistOptions] = useState([]);
  const [RoomOptions, setRoomOptions] = useState([]);

  const [formData, setFormData] = useState({
    client: '',
    therapist: '',
    date: '',
    starttime: '12:00:00',
    endtime: '12:00:00',
    telehealth: false,
    room: '',
    reminder: '0',
    link: ''
  });

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};
    let isValid = true;
  
    const today = new Date().toISOString().split('T')[0];
    const selectedDate = new Date(formData.date).toISOString().split('T')[0];
    const currentTime = new Date().toTimeString().split(' ')[0].slice(0, 5);
  
    // Check for required fields
    if (!formData.date) {
      newErrors.date = 'Date is required';
      isValid = false;
    }
    if (!formData.starttime) {
      newErrors.starttime = 'Start time is required';
      isValid = false;
    }
    if (!formData.endtime) {
      newErrors.endtime = 'End time is required';
      isValid = false;
    }
    if (selectedDate < today) {
      newErrors.date = 'You cannot select a past date.';
      isValid = false;
    }
    if (formData.date === today && formData.starttime < currentTime) {
      newErrors.starttime = 'You cannot book a time earlier than the current time.';
      isValid = false;
    }
    if (formData.starttime >= formData.endtime) {
      newErrors.timeRange = 'End time must be later than start time';
      isValid = false;
    }
    if (formData.telehealth === 1 && !formData.link) {
      newErrors.link = 'Telehealth link is required';
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };
  

  useEffect(() => {
    const fetchAppointmentData = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`https://sandbox.purplecloud.io/api/appointment/update_appointment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token, appid: id }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        setClientOptions(data.data.client || []);
        setTherapistOptions(data.data.therapist || []);
        setRoomOptions(data.data.arrRooms || []);

        const startDateTime = data.data.arrAppointmentRow.appDate || '2024-08-12 12:00:00';
        const endDateTime = data.data.arrAppointmentRow.appt_end_time || '2024-08-12 12:00:00';
        const [startDate, startTime] = startDateTime.split(' ');
        const [endDate, endTime] = endDateTime.split(' ');

        setFormData((prevState) => ({
          ...prevState,
          client: data.data.arrAppointmentRow.clientfkID || '',
          therapist: data.data.therapist_id || '',
          date: startDate,
          starttime: startTime || '12:00:00',
          endtime: endTime || '12:00:00',
          telehealth: data.data.arrAppointmentRow.telehealth,
          room: data.data.arrAppointmentRow.room_id || 0,
          reminder: data.data.arrAppointmentRow.send_sms || '0',
          link: data.data.link || ''
        }));

      } catch (err) {
        console.error('Failed to fetch appointment data:', err);
        alert('Failed to fetch appointment data.');
      }
    };

    fetchAppointmentData();
  }, [id]);

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#7A65AC',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSwitchChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      telehealth: e.target.checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`https://sandbox.purplecloud.io/api/appointment/edit_appointment`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token,
            appid: id,  // Appointment ID
            ...formData // Form data
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Handle success or navigate to another page if needed
        alert('Appointment updated successfully!');
        navigate(`${baseUrl}/welcome/dashboard`);

      } catch (err) {
        console.error('Failed to update appointment:', err);
        alert('Failed to update appointment.');
      }
    }
  };

  return (
    <>
      <div className=''>
        <div className='flex items-center justify-between bg-darklightgrey'>
          <div className='container mx-auto flex justify-between py-[20px] px-[40px]'>
            <h3 className='text-black text-[20px] md:text-[40px] font-bold'>Update Schedule Appointment</h3>
            <div className='flex items-center gap-5'>
              <button className='bg-primary text-white w-[150px] md:w-[300px] md:flex hidden items-center justify-center py-[15px] rounded-[60px] font-bold text-[23px]' onClick={handleSubmit}>Update</button>
              <Link to={`${baseUrl}/welcome/dashboard`} className='text-[20px] font-bold text-primary'><Image src="common/cross-sign.png" alt="logo" width="35" height="30" /></Link>
            </div>
          </div>
        </div>
        <div className='container mx-auto md:mx-0 w-[100%] md:w-[50%] p-[20px]'>
          <div className="lg:p-6">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <label htmlFor="client" className="block text-primary font-bold text-[20px]">Client:</label>
                  <select name="client" id="client" className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                    value={formData.client} // Set selected value
                    onChange={handleChange}
                  >
                    {clientOptions.map(client => (
                      <option key={client.client_id} value={client.client_id}>
                        {client.lname}, {client.fname}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <label htmlFor="date" className="block text-primary font-bold text-[20px]">Date:</label>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                  />
                  {errors.date && <span className="text-red-500">{errors.date}</span>}
                </div>
                <div className="flex-1 mt-4 md:mt-0">
                  <label htmlFor="starttime" className="block text-primary font-bold text-[20px]">Start Time:</label>
                  <input
                    type="time"
                    id="starttime"
                    name="starttime"
                    value={formData.starttime}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                  />
                   {errors.starttime && <span className="text-red-500">{errors.starttime}</span>}
                </div>
                <div className="flex-1 mt-4 md:mt-0">
                  <label htmlFor="endtime" className="block text-primary font-bold text-[20px]">End Time:</label>
                  <input
                    type="time"
                    id="endtime"
                    name="endtime"
                    value={formData.endtime}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                  />
                   {errors.endtime && <span className="text-red-500">{errors.endtime}</span>}
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <label htmlFor="cancel" className="block text-primary font-bold text-[20px]">Cancel:</label>
                  <select
                    id="cancel"
                    name="cancel"
                    value={formData.cancel}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                  >
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                    
                  </select>
                </div>
              </div>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <div className="flex-1">
                  <label htmlFor="therapist" className="block text-primary font-bold text-[20px]">Therapist:</label>
                  <select
                    id="therapist"
                    name="therapist"
                    value={formData.therapist}
                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                  >
                    {therapistOptions.map(therapist => (
                      <option key={therapist.client_id} value={therapist.client_id}>
                        {therapist.lname}, {therapist.fname}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="flex items-center mt-4 md:mt-0">
                <div className="flex-1">
                  <label htmlFor="telehealth" className="text-primary font-bold text-[20px] mr-4">Telehealth:</label>
                  {formData.telehealth == 1 ? (
                    <FormGroup>
                      <FormControlLabel
                        control={<IOSSwitch checked={true} onChange={handleSwitchChange} />}

                        className="mt-5 pl-5"
                        name="telehealth"
                      />
                    </FormGroup>
                  ) : (
                    <FormGroup>
                      <FormControlLabel
                        control={<IOSSwitch checked={false} onChange={handleSwitchChange} />}

                        className="mt-5 pl-5"
                        name="telehealth"
                      />
                    </FormGroup>
                  )}
                </div>
                {formData.telehealth == 1 ? (
                  <div className='flex-1'>
                    <label htmlFor="reminder" className="block text-primary font-bold text-[20px]">Telehealth Link:</label>
                    <input
                      type="text"
                      id="link"
                      name="link"
                      value={formData.link}
                      onChange={handleChange}
                      placeholder='Please paste your telehealth meeting link here.'
                      className="mt-1 block w-full shadow-sm focus:border-indigo-300 h-[45px] md:h-[63px] rounded-[24px] border border-lightpurple focus:outline-none focus:shadow-none focus:ring-0 px-5 text-primary"
                    />
                   {errors.link && <span className="text-red-500">{errors.link}</span>}
                  </div>
                ) : (
                  <div className="flex-1">
                    <label htmlFor="room" className="block text-primary font-bold text-[20px]">Room:</label>
                    <select
                      id="room"
                      name="room"
                      value={formData.room}
                      onChange={handleChange}
                      className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                    >
                      {RoomOptions.map(room => (
                        <option key={room.room_uid} value={room.room_uid}>{room.title} </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>

              <div className="flex flex-col md:flex-row md:space-x-4">

                <div className="flex-1 mt-4 md:mt-0">
                  <label htmlFor="reminder" className="block text-primary font-bold text-[20px]">Reminder:</label>
                  <select
                    id="reminder"
                    name="reminder"

                    onChange={handleChange}
                    className="mt-1 block w-full shadow-sm border border-lightpurple rounded-[24px] h-[45px] md:h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary font-bold"
                  >
                    <option value="0" selected={formData.reminder === "0"}>No</option>
                    <option value="1" selected={formData.reminder === "1"}>Yes</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateAppointment;
