import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Function to format date and time
const formatDateTime = (dateTimeStr) => {
    const date = new Date(dateTimeStr);
    const optionsDate = { month: 'numeric', day: 'numeric', year: '2-digit' };
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
    return `${date.toLocaleDateString('en-US', optionsDate)} ${date.toLocaleTimeString('en-US', optionsTime)}`;
};

function CustomTabPanel(props) {
    const { children, value, index, className, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={className}
            {...other}
        >
            {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const baseUrl = '/purple-cloud';

const InsurenceVerification = () => {
    const [value, setValue] = useState(0);
    const [pendingInsurance, setPendingInsurance] = useState([]);
    const [verifiedInsurance, setVerifiedInsurance] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchInsuranceData = async () => {
        if (!token) {
            setError('Token is missing. Please log in again.');
            setLoading(false);
            return;
        }

        try {
            const response = await fetch('https://sandbox.purplecloud.io/Api/InsurenceApi/insurance_verification', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            const data = await response.json();
            if (data.status === 'success') {
                setPendingInsurance(data.data.arrInsurance_pending || []);
                setVerifiedInsurance(data.data.arrInsurance_verified || []);
            } else {
                setError('Failed to fetch insurance data.');
            }
        } catch (err) {
            setError(`Failed to fetch insurance data: ${err.message}`);
            console.error('Failed to fetch insurance data:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInsuranceData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    const handleViewClick = async (ins_id) => {
        console.log('handleViewClick called with ins_id:', ins_id); // Debugging

        if (!token) {
            setError('Token is missing. Please log in again.');
            return;
        }

        if (ins_id === undefined || ins_id === null) {
            setError('Invalid insurance ID.');
            return;
        }

        try {
            const response = await fetch('https://sandbox.purplecloud.io/Api/InsurenceApi/getUpdate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token, ins_id }),  // Pass both token and ins_id
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            const data = await response.json();
            console.log('API Response:', data);  // Debugging: Log the response from API

            if (data.status === 'success') {
                navigate(`${baseUrl}/insurence-verification/${ins_id}`);
            } else {
                setError('Failed to fetch insurance details.');
            }
        } catch (err) {
            setError(`Failed to fetch insurance details: ${err.message}`);
            console.error('Failed to fetch insurance details:', err);
        }
    };




    return (
        <>
            <div className='flex items-center justify-between'>
                <h2 className='text-[20px] text-primary font-bold mb-5'>Insurance Verification</h2>
            </div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="insurance tabs">
                        <Tab label="Pending" {...a11yProps(0)} sx={{ fontSize: '20px', color: '#7A65AC !important', fontWeight: 'bold' }} />
                        <Tab label="Verified" {...a11yProps(1)} sx={{ fontSize: '20px', color: '#7A65AC !important', fontWeight: 'bold' }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0} className="your-tab-panel-class">
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead className="border-t border-b border-gray-200">
                                <tr>
                                    <th className="text-left text-tablehead font-normal">DATE</th>
                                    <th className="text-left text-tablehead font-normal">THERAPIST</th>
                                    <th className="text-left text-tablehead font-normal">CLIENT ID</th>
                                    <th className="text-left text-tablehead font-normal">CARRIER NAME</th>
                                    <th className="text-left text-tablehead font-normal">VIEW</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pendingInsurance.length ? (
                                    pendingInsurance.map((item) => (
                                        <tr key={item.insurance_id}>
                                            <td className="text-[#3b3c51] font-bold text-[17.36px]">
                                                {formatDateTime(item.date)}
                                            </td>
                                            <td className="text-white font-bold text-[17.36px] bg-primary rounded-full flex items-center justify-center max-w-min p-1">
                                                {item.therapist_fname?.charAt(0)}{item.therapist_lname?.charAt(0)}
                                            </td>
                                            <td className="text-[#3b3c51] font-bold text-[17.36px]">{item.client_id}</td>
                                            <td className="text-[#3b3c51] font-bold text-[17.36px]">{item.carrier_name}</td>
                                            <td>
                                                <Button
                                                    onClick={() => handleViewClick(item.insurance_id)}
                                                    className='flex items-center justify-center text-[15px] font-bold text-white bg-primary px-5 rounded-xl py-1 w-[155px]'
                                                >
                                                    VIEW
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className='font-bold text-primary text-center py-2'>No pending insurance records found.</td>
                                    </tr>
                                )}
                            </tbody>

                        </table>
                    </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1} className="your-tab-panel-class">
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            <thead className="border-t border-b border-gray-200">
                                <tr>
                                    <th className="text-left text-tablehead font-normal">REQUESTED DATE</th>
                                    <th className="text-left text-tablehead font-normal">VERIFIED DATE</th>
                                    <th className="text-left text-tablehead font-normal">THERAPIST</th>
                                    <th className="text-left text-tablehead font-normal">CLIENT ID</th>
                                    <th className="text-left text-tablehead font-normal">CARRIER NAME</th>
                                    <th className="text-left text-tablehead font-normal">VIEW</th>
                                </tr>
                            </thead>
                            <tbody>
                                {verifiedInsurance.length ? (
                                    verifiedInsurance.map((item) => (
                                        <tr key={item.ins_id} className='h-[50px]'>
                                            <td className="text-[#3b3c51] font-bold text-[17.36px]">
                                                {formatDateTime(item.verification_requested_at)}
                                            </td>
                                            <td className="text-[#3b3c51] font-bold text-[17.36px]">
                                                {formatDateTime(item.verified_at)}
                                            </td>
                                            <td className="text-white font-bold text-[17.36px] bg-primary rounded-full flex items-center justify-center max-w-min p-1">
                                                {item.therapist_fname?.charAt(0)}{item.therapist_lname?.charAt(0)}
                                            </td>
                                            <td className="text-[#3b3c51] font-bold text-[17.36px]">{item.client_unique_id}</td>
                                            <td className="text-[#3b3c51] font-bold text-[17.36px]">{item.carrier_name}</td>
                                            <td>
                                                <Button
                                                    onClick={() => handleViewClick(item.ins_id)}
                                                    className='flex items-center justify-center text-[15px] font-bold text-white bg-primary hover:bg-primary  px-5 rounded-xl py-1 w-[155px]'
                                                >
                                                    VIEW
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className='font-bold text-primary text-center py-2'>No verified insurance records found.</td>
                                    </tr>
                                )}
                            </tbody>


                        </table>
                    </div>
                </CustomTabPanel>
            </Box>
        </>
    );
}

export default InsurenceVerification;
