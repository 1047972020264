import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdModeEditOutline } from "react-icons/md";


const baseUrl = "/purple-cloud";

function formatTimeRange(start, end) {
   

    const formatTime = (dateString) => {
        if (!dateString) {
           
            return 'Invalid time';
        }

        const formattedDateString = dateString.replace(' ', 'T');
      
        const date = new Date(formattedDateString);
        if (isNaN(date.getTime())) {
          
            return 'Invalid time'; // Handle invalid date
        }

        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        /* console.log(`Parsed Time - Hours: ${hours}, Minutes: ${minutes}, AM/PM: ${ampm}`); */

        return `${hours}:${minutes}${ampm}`;
    };

    const startTime = formatTime(start);
    const endTime = formatTime(end);

    return `${startTime} - ${endTime}`;
}

function Daysheet() {
    const [daysheetData, setDaysheetData] = useState([]);
    const [daysheetDetail, setDaysheetDetail] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [message, setMessage] = useState("");
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [selectedDaysheetId, setSelectedDaysheetId] = useState(null);
    const [selectedDaysheetDetail, setSelectedDaysheetDetail] = useState([]);
    const [therapistData, setTherapistData] = useState(null);
    const [notesData, setNotesData] = useState([]);
    const [workingHours, setWorkingHours] = useState(null);

    useEffect(() => {
        const fetchDaysheetData = async (daysheetId = null) => {
            const token = localStorage.getItem('token');

            try {
                const response = await fetch('https://sandbox.purplecloud.io/api/day-sheet', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token,
                        month: selectedMonth,
                        year: selectedYear,
                        daysheet_id: daysheetId,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                if (data.msg) {
                    setMessage(data.msg);
                    setDaysheetData([]);
                } else {
                    setMessage("");
                    setDaysheetData(data.data.daysheet || []);
                    setTherapistData(data.data.therapist[0] || null);
                    setDaysheetDetail(data.data.day_sheet_data || null);
                    setWorkingHours(data.data.working_hours);

                    if (daysheetId) {
                        setSelectedDaysheetDetail(data.data.notes.client_detail || []);
                        
                        const note_start_at = data.data.notes.note_start_at;
                        const note_end_at = data.data.notes.note_end_at;

                       /*  console.log('note_start_at:', note_start_at);
                        console.log('note_end_at:', note_end_at); */

                        const timeRange = formatTimeRange(note_start_at, note_end_at);

                        setNotesData(data.data.notes || []);
                    }
                }
            } catch (err) {
                console.error('Failed to fetch daysheet data:', err);
            }
        };

        fetchDaysheetData(selectedDaysheetId);
    }, [selectedMonth, selectedYear, selectedDaysheetId]);

    const handlePreviewClick = (daysheetId) => {
        setSelectedDaysheetId(daysheetId);

        setIsPreviewVisible(true);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear().toString().slice(-2);
        return `${month}/${day}/${year}`;
    };

    const timeRange = (start, end) => formatTimeRange(start, end);

    return (
        <>
            {!isPreviewVisible ? (
                <div>
                    <div className='flex gap-5 justify-between'>
                        <h2 className='text-[20px] text-primary font-bold'>Day Sheets</h2>
                        <div>
                            <form action="#" method="POST">
                                <div className='flex gap-3 flex-col md:flex-row items-center'>
                                    <select
                                        name="month"
                                        id="month"
                                        value={selectedMonth}
                                        onChange={(e) => setSelectedMonth(e.target.value)}
                                        className='border-2 px-2 py-1 border-primary rounded-[40px] md:text-[14px] text-primary font-bold focus:outline-none cursor-pointer shadow-md text-[12px]'
                                    >
                                        {[...Array(12).keys()].map(i => (
                                            <option key={i + 1} value={i + 1}>{new Date(0, i).toLocaleString('default', { month: 'long' })}</option>
                                        ))}
                                    </select>
                                    <select
                                        name="year"
                                        id="year"
                                        value={selectedYear}
                                        onChange={(e) => setSelectedYear(e.target.value)}
                                        className='border-2 px-2 py-1 border-primary rounded-[40px] md:text-[14px] text-primary font-bold focus:outline-none cursor-pointer shadow-md text-[12px]'
                                    >
                                        {[2021, 2022, 2023, 2024, 2025].map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full my-5">
                            <thead className='border-t border-b border-gray-200'>
                                <tr>
                                    <th className='text-left text-tablehead font-normal'>Date</th>
                                    <th className='text-left text-tablehead font-normal'>Hours</th>
                                    <th className='text-left text-tablehead font-normal'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {daysheetData.length > 0 ? (
                                    daysheetData.map((item, index) => (
                                        <tr key={index}>
                                            <td className='text-[#3b3c51] font-bold text-[17.36px] py-2'>{formatDate(item.cdate)}</td>
                                            <td className='text-[#3b3c51] font-bold text-[17.36px] py-2'>{item.working_hours}</td>
                                            <td className='text-[#3b3c51] font-bold text-[17.36px] py-2 flex items-center justify-end'>
                                                <button
                                                    onClick={() => handlePreviewClick(item.daysheet_id)}
                                                    aria-label={`Preview day sheet ${item.daysheet_id}`}
                                                    className='p-3 border-2 border-primary text-primary rounded-[60px] flex items-center justify-center py-1 font-bold hover:bg-primary hover:text-white transition text-[12px] md:text-[15px]'>
                                                    Preview
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className='font-bold text-primary text-center py-2'>
                                            No day sheets match this month and year.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div>
                    <p className='text-[40px] mb-0'>head head</p>
                    <p className='text-[20px] text-primary font-bold'>
                        Day Sheet - {/* {daysheetDetail.cdate} */} - Hours: {workingHours}
                    </p>
                    <table className="table-auto w-full">
                        <thead className="border-t border-b border-gray-200">
                            <tr>
                                <th className="text-left text-tablehead font-normal uppercase">Time</th>
                                <th className="text-left text-tablehead font-normal uppercase">Client</th>
                                <th className="text-left text-tablehead font-normal uppercase">Diagnosis</th>
                                <th className="text-left text-tablehead font-normal uppercase">Billing Code</th>
                                <th className="text-left text-tablehead font-normal uppercase">Co-Pay</th>
                                <th className="text-left text-tablehead font-normal uppercase">TH Co-Pay</th>
                              
                                <th className="text-left text-tablehead font-normal uppercase">Action</th>
                            </tr>
                        </thead>
                        <tbody className='relative'>
                            {notesData.length > 0 ? (
                                notesData.map((detail, index) => (
                                    <tr key={index}>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px] py-5">{timeRange(detail.start_time, detail.end_time)}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">{`${detail.lname}, ${detail.fname}`}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">{detail.diagnosis || 'N/A'}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">{notesData.code || 'N/A'}</td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px]">
                                            (${notesData.insurance_the_copay_amount || '0'}) {notesData.co_pay || 'N/A'}
                                        </td>
                                        <td className="text-[#3b3c51] font-bold text-[17.36px] py-2">{notesData.insurance_the_copay_amount || 'N/A'}</td>
                                        <td className="font-bold text-[17.36px] flex items-center justify-center py-5">
                                            {/* <details>
                                                <summary className="flex items-center cursor-pointer">
                                                    <span className="flex-grow">View Notes</span>
                                                    <svg
                                                        className="w-4 h-4 ml-2 transition-transform duration-300"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <path d="M19 9l-7 7-7-7" />
                                                    </svg>
                                                </summary>
                                                <p className="mt-2 text-sm absolute left-0 py-3">
                                                    Epcot is a theme park at Walt Disney World Resort featuring exciting attractions, international pavilions, award-winning fireworks, and seasonal special events.
                                                </p>
                                            </details> */}
                                            <Link to={`/day-sheet/preview/${notesData.note_id}`} aria-label={`Edit note ${notesData.note_id}`} className="p-2 text-primary">
                                                <MdModeEditOutline />
                                            </Link>
                                        </td>
                                        <td className="font-bold text-[17.36px]">
                                            {/* Add any actions you need here */}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className='font-bold text-primary text-center py-2'>
                                        No day sheet details available.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default Daysheet;
