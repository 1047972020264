import { useState, useEffect } from 'react';

const useFetch = (url, pollInterval = 10000) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');
        const result = await response.json();
        setData(result);
        console.log('Fetched result:', result); 
      } catch (error) {
        setError(error);
        console.error('Fetch error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, pollInterval);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [url, pollInterval]);

  return { data, loading, error };
};

export default useFetch;
