import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Image from '../../components/image/Image';

const baseUrl = "/purple-cloud";
const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters and limit to 10 digits
    const cleaned = ('' + value).replace(/\D/g, '').slice(0, 10);

    // Match the cleaned string with the format
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    // Format the phone number
    if (match) {
        const [, area, central, line] = match;
        return `(${area}${central ? ') ' : ''}${central}${line ? '-' : ''}${line}`;
    }

    return value;
};

function NewTherapist() {
    // State for form fields
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        phoneNumber: '',
    });

    // State for errors
    const [errors, setErrors] = useState({});

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;

        // Format phone number field
        if (name === 'phoneNumber') {
            setFormValues(prevValues => ({
                ...prevValues,
                [name]: formatPhoneNumber(value)
            }));
        } else {
            setFormValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    };

    // Validate form fields
    const validate = () => {
        const newErrors = {};

        // Check for required fields
        if (!formValues.firstName) newErrors.firstName = 'First Name is required';
        if (!formValues.lastName) newErrors.lastName = 'Last Name is required';
        if (!formValues.email) newErrors.email = 'Email Address is required';
        if (formValues.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
            newErrors.email = 'Invalid email address';
        }
        if (!formValues.confirmEmail) newErrors.confirmEmail = 'Confirm Email Address is required';
        if (formValues.email !== formValues.confirmEmail) newErrors.confirmEmail = 'Emails do not match';
        if (!formValues.phoneNumber) newErrors.phoneNumber = 'Phone Number is required';
        if (formValues.phoneNumber && !/^\(\d{3}\) \d{3}-\d{4}$/.test(formValues.phoneNumber)) {
            newErrors.phoneNumber = 'Invalid phone number (must be in the format (###) ###-####)';
        }

        return newErrors;
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            console.log("Form Data:", formValues);
            // Handle form submission logic
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <>  
            <div className=''>
                <div className='flex items-center justify-between bg-darklightgrey'>
                    <div className='container mx-auto flex justify-between py-[20px] px-[40px]'>
                        <h3 className='text-black text-[20px] md:text-[40px] font-bold'>Add Therapist</h3>
                        <div className='flex items-center gap-5'>
                            <button 
                                type="submit"
                                form="therapistForm"
                                className='bg-primary text-white w-[150px] md:w-[300px] md:flex hidden items-center justify-center py-[15px] rounded-[60px] font-bold text-[23px]' 
                            >
                                Save
                            </button>
                            <Link to={`${baseUrl}/therapist`} className='text-[20px] font-bold text-primary'>
                                <Image src="common/cross-sign.png" alt="logo" width="35" height="30" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto md:px-10">
                    <form id="therapistForm" onSubmit={handleSubmit} className="space-y-6">
                        <div className="flex flex-col md:flex-row md:space-x-4">
                            <div className="flex-1">
                                <label htmlFor="firstName" className="block text-primary font-bold text-[20px]">First Name:</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={formValues.firstName}
                                    onChange={handleChange}
                                    className={`mt-1 block w-full shadow-sm border ${errors.firstName ? 'border-red-500' : 'border-lightpurple'} rounded-[24px] h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary`}
                                    autoComplete='off'
                                />
                                {errors.firstName && <p className="text-red-500 text-sm">{errors.firstName}</p>}
                            </div>
                            <div className="flex-1 mt-4 md:mt-0">
                                <label htmlFor="lastName" className="block text-primary font-bold text-[20px]">Last Name:</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={formValues.lastName}
                                    onChange={handleChange}
                                    className={`mt-1 block w-full shadow-sm border ${errors.lastName ? 'border-red-500' : 'border-lightpurple'} rounded-[24px] h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary`}
                                    autoComplete='off'
                                />
                                {errors.lastName && <p className="text-red-500 text-sm">{errors.lastName}</p>}
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row md:space-x-4">
                            <div className="flex-1">
                                <label htmlFor="email" className="block text-primary font-bold text-[20px]">Email Address:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formValues.email}
                                    onChange={handleChange}
                                    className={`mt-1 block w-full shadow-sm border ${errors.email ? 'border-red-500' : 'border-lightpurple'} rounded-[24px] h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary`}
                                    autoComplete='off'
                                />
                                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                            </div>
                            <div className="flex-1 mt-4 md:mt-0">
                                <label htmlFor="confirmEmail" className="block text-primary font-bold text-[20px]">Confirm Email Address:</label>
                                <input
                                    type="email"
                                    id="confirmEmail"
                                    name="confirmEmail"
                                    value={formValues.confirmEmail}
                                    onChange={handleChange}
                                    className={`mt-1 block w-full shadow-sm border ${errors.confirmEmail ? 'border-red-500' : 'border-lightpurple'} rounded-[24px] h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary`}
                                    autoComplete='off'
                                />
                                {errors.confirmEmail && <p className="text-red-500 text-sm">{errors.confirmEmail}</p>}
                            </div>
                        </div>

                        <div className="flex flex-col mt-6">
                            <div className="flex-1">
                                <label htmlFor="phoneNumber" className="block text-primary font-bold text-[20px]">Phone Number:</label>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formValues.phoneNumber}
                                    onChange={handleChange}
                                    className={`mt-1 block w-full shadow-sm border ${errors.phoneNumber ? 'border-red-500' : 'border-lightpurple'} rounded-[24px] h-[63px] focus:border-indigo-300 focus:outline-none focus:ring-0 px-5 text-primary`}
                                    autoComplete='off'
                                />
                                {errors.phoneNumber && <p className="text-red-500 text-sm">{errors.phoneNumber}</p>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default NewTherapist;
