import React from 'react';
import { FaCalendarAlt, FaChevronLeft, FaChevronRight, FaGoogle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function CustomToolbar(props) {
    const {
        localizer: { messages },
        label,
        onNavigate,
        onView,
    } = props;

    const goToBack = () => {
        onNavigate('PREV');
    };

    const goToNext = () => {
        onNavigate('NEXT');
    };

    const goToToday = () => {
        onNavigate('TODAY');
    };

    const handleViewChange = (view) => {
        onView(view);
    };

    return (
        <div className="rbc-toolbar items-center gap-3">
            <div className="flex items-center order-1">
                <label htmlFor="therapist-select" className='text-xl font-bold text-primary'>Therapist : </label>
                <select
                    id="therapist-select"
                    value={props.selectedTherapist}
                    onChange={props.onTherapistChange}
                    className='border-2 px-2 font-bold text-primary rounded-full py-1 ml-2'
                >
                    {props.therapists.map((therapist) => (
                        <option key={therapist} value={therapist}>
                            {therapist}
                        </option>
                    ))}
                </select>
            </div>
            <span className="rbc-toolbar-label text-xl font-bold text-primary order-3 lg:order-2">{label}</span>
            <div className="flex flex-col sm:flex-row  gap-3 justify-center items-center order-2 lg:order-3">
                <div className=" flex items-center justify-center">
                    <span className="rbc-btn-group">
                        <button type="button" onClick={goToBack} className='bg-primary text-white'>
                            <FaChevronLeft />
                        </button>
                        <button type="button" onClick={goToNext} className=''>
                            <FaChevronRight />
                        </button>
                    </span>
                    <span className="rbc-btn-group">
                        <button type="button" onClick={goToToday}>
                            {messages.today}
                        </button>
                    </span>
                </div>

                <div className="flex gap-2 items-center justify-center">
                    <span className="rbc-btn-group">
                        <button type="button" onClick={() => handleViewChange('month')}>
                            {messages.month}
                        </button>
                        <button type="button" onClick={() => handleViewChange('week')}>
                            {messages.week}
                        </button>
                        <button type="button" onClick={() => handleViewChange('day')}>
                            {messages.day}
                        </button>
                    </span>
                    <div className="relative subscribe-box w-max">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="25px" viewBox="0 0 9.727 9.878">
                            <g id="Group_247" data-name="Group 247" transform="translate(266.798 127.674)">
                                <path id="Path_779" data-name="Path 779" d="M-261.772-124.382h-4.185c-.062,0-.055-.007-.055.054q0,2.635,0,5.271a.415.415,0,0,0,.227.4.435.435,0,0,0,.21.046c.177,0,.353,0,.53,0a.386.386,0,0,1,.367.2.369.369,0,0,1,0,.384.373.373,0,0,1-.343.2c-.222,0-.445.008-.667,0a1.114,1.114,0,0,1-.5-.142,1.209,1.209,0,0,1-.384-.349,1.2,1.2,0,0,1-.2-.45,1.124,1.124,0,0,1-.02-.229q0-3.561,0-7.122a1.121,1.121,0,0,1,.108-.486,1.2,1.2,0,0,1,.5-.541,1.059,1.059,0,0,1,.436-.137c.269-.023.538-.005.807-.009.04,0,.055,0,.063-.054a.356.356,0,0,1,.16-.249.4.4,0,0,1,.218-.071.38.38,0,0,1,.315.148.4.4,0,0,1,.086.256c0,.246,0,.493,0,.74a.393.393,0,0,1-.357.385.388.388,0,0,1-.42-.315c-.008-.043-.021-.056-.066-.056-.212,0-.425,0-.637,0a.414.414,0,0,0-.433.433c0,.286,0,.573,0,.859,0,.04.008.052.051.052h7.99c.037,0,.085.016.107-.006s.008-.073.008-.111q0-.4,0-.8a.416.416,0,0,0-.3-.416.472.472,0,0,0-.127-.015c-.214,0-.427,0-.641,0-.042,0-.056.011-.064.053a.386.386,0,0,1-.371.319.392.392,0,0,1-.391-.291c-.022-.08-.022-.08-.1-.08H-261.5c-.033,0-.046.008-.052.042a.391.391,0,0,1-.58.278.349.349,0,0,1-.191-.276c0-.038-.02-.044-.054-.044-.249,0-.5,0-.748,0a.358.358,0,0,1-.24-.125.372.372,0,0,1-.117-.252.366.366,0,0,1,.125-.294.455.455,0,0,1,.331-.114c.214,0,.427,0,.641,0,.039,0,.057-.006.062-.05a.352.352,0,0,1,.2-.28.393.393,0,0,1,.338-.014.383.383,0,0,1,.238.308c0,.025.012.035.04.035h1.7c.024,0,.034,0,.037-.033a.359.359,0,0,1,.082-.19.479.479,0,0,1,.142-.115.327.327,0,0,1,.175-.033.378.378,0,0,1,.318.174.357.357,0,0,1,.061.161c0,.031.017.038.047.038.231,0,.462,0,.693,0a1.2,1.2,0,0,1,.589.164,1.172,1.172,0,0,1,.206.151,1.15,1.15,0,0,1,.24.312,1.171,1.171,0,0,1,.144.553c0,.4,0,.8,0,1.193a.52.52,0,0,1-.07.278.537.537,0,0,1-.428.266c-.028,0-.057,0-.085,0Z" fill="#7A65AC"></path>
                                <path id="Path_780" data-name="Path 780" d="M-5.515,349.137c.05.034.091.057.127.086a.349.349,0,0,1,.063.484.355.355,0,0,1-.48.086l-.488-.3c-.11-.067-.217-.14-.33-.2a.389.389,0,0,1-.179-.178.34.34,0,0,1,.033-.365c.1-.136.2-.27.3-.4s.2-.276.309-.413a.353.353,0,0,1,.484-.083.356.356,0,0,1,.116.455.082.082,0,0,0-.017.042l.139-.02a2.586,2.586,0,0,1,.745,0,2.6,2.6,0,0,1,1.128.448,2.614,2.614,0,0,1,.916,1.117,2.6,2.6,0,0,1,.207,1.309,2.644,2.644,0,0,1-.308,1.014,2.631,2.631,0,0,1-.858.94,2.551,2.551,0,0,1-.556.282,2.654,2.654,0,0,1-1.113.155,2.725,2.725,0,0,1-.7-.15,2.472,2.472,0,0,1-.413-.193,2.676,2.676,0,0,1-.691-.573,2.819,2.819,0,0,1-.348-.514,2.429,2.429,0,0,1-.162-.377,1.9,1.9,0,0,1-.1-.359,3.066,3.066,0,0,1,.01-.977c.018-.1.046-.189.071-.283a1.661,1.661,0,0,1,.163-.4.385.385,0,0,1,.505-.158.4.4,0,0,1,.185.538,1.8,1.8,0,0,0-.132,1.227A1.81,1.81,0,0,0-6.1,352.5a1.8,1.8,0,0,0,1.346.282,1.827,1.827,0,0,0,.941-.465,1.84,1.84,0,0,0,.368-.466,2.08,2.08,0,0,0,.114-.257,1.741,1.741,0,0,0,.119-.725,1.851,1.851,0,0,0-.111-.561,1.858,1.858,0,0,0-.467-.709,1.84,1.84,0,0,0-.821-.457c-.056-.014-.114-.02-.171-.031a1.564,1.564,0,0,0-.491-.015C-5.353,349.107-5.427,349.123-5.515,349.137Z" transform="translate(-256.856 -471.396)" fill="#7A65AC"></path>
                                <path id="Path_781" data-name="Path 781" d="M618.844,333.2q0,1.064,0,2.128a1.271,1.271,0,0,1-.139.582,1.173,1.173,0,0,1-.188.266,1.122,1.122,0,0,1-.25.2,1.142,1.142,0,0,1-.515.163c-.235.015-.47.007-.7,0a.352.352,0,0,1-.342-.229.4.4,0,0,1,.122-.482.372.372,0,0,1,.233-.073c.185,0,.37,0,.556,0a.454.454,0,0,0,.306-.111.423.423,0,0,0,.121-.219.552.552,0,0,0,.014-.159q0-2.12,0-4.24a.418.418,0,0,1,.359-.411.4.4,0,0,1,.429.4q0,.923,0,1.846Z" transform="translate(-875.914 -454.38)" fill="#7A65AC"></path>
                            </g>
                        </svg>
                    </span>
                    <div className="subscribe absolute -right-3 md:right-3/4 top-full w-[300px] bg-white z-10">
                        <ul>
                            <li>
                                <Link to=""> <FaCalendarAlt /> Subscribe to iCalendar </Link>
                            </li>
                            <li>
                                <Link to=""> <FaGoogle />  Subscribe with Google Calendar </Link>
                            </li>
                            <li>
                                <Link to=""> <FaCalendarAlt />  Subscribe with Microsoft Live</Link>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomToolbar;
