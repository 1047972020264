import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './myCalendar.css';
import CustomToolbar from './CustomToolbar';

const localizer = momentLocalizer(moment);

const data = [
  {
    title: 'Meeting with Dr. Smith',
    start: new Date(2024, 7, 1, 9, 0), // August 1, 2024, 9:00 AM
    end: new Date(2024, 7, 1, 10, 0),
    therapist: 'Dr. Smith',
  },
  {
    title: 'Appointment with Dr. Johnson',
    start: new Date(2024, 7, 15, 14, 0), // August 15, 2024, 2:00 PM
    end: new Date(2024, 7, 15, 15, 0),
    therapist: 'Dr. Johnson',
  },
];

const therapists = ['All', 'Dr. Smith', 'Dr. Johnson'];

function MyCalendar() {
  const [events, setEvents] = useState(data);
  const [selectedTherapist, setSelectedTherapist] = useState('All');

  const eventStyleGetter = (event) => {
    const hour = event.start.getHours();
    return {
      style: {
        backgroundColor: hour < 12 ? '#049AE4' : '#e74c3c',
        color: '#fff',
        borderRadius: '5px',
        border: 'none',
      },
    };
  };

  const handleTherapistChange = (e) => {
    const therapist = e.target.value;
    setSelectedTherapist(therapist);

    if (therapist === 'All') {
      setEvents(data);
    } else {
      const filteredEvents = data.filter(
        (event) => event.therapist === therapist
      );
      setEvents(filteredEvents);
    }
  };

  return (
    <div className="therapistCalendar">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={eventStyleGetter}
        components={{
          toolbar: (props) => (
            <CustomToolbar
              {...props}
              selectedTherapist={selectedTherapist}
              onTherapistChange={handleTherapistChange}
              therapists={therapists}
            />
          ),
        }}
      />
    </div>
  );
}

export default MyCalendar;
